import React from 'react';
import { ITabs } from '../../types/Tabs';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

interface ITabsProps {
  tabs: ITabs[];
  currentTabIndex: number;
  handleTabs: (index: number) => void;
}

const Tabs: React.FC<ITabsProps> = ({ tabs, currentTabIndex, handleTabs }) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedIndex = event.target.selectedIndex;
    handleTabs(selectedIndex);
  };

  return (
    <div>
      <div className='sm:hidden'>
        <label htmlFor='tabs' className='sr-only'>
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id='tabs'
          name='tabs'
          className='block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm'
          onChange={handleChange}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className='hidden sm:block'>
        <div className='border-b border-gray-200'>
          <nav className='-mb-px flex space-x-8' aria-label='Tabs'>
            {tabs.map((tab, index) => (
              <a
                key={tab.id}
                className={classNames(
                  currentTabIndex === index
                    ? 'border-sky-500 text-sky-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 hover:cursor-pointer',
                  'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                )}
                onClick={() => handleTabs(index)}
              >
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
