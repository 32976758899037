import React, { useRef, useState } from 'react';
import styled from 'styled-components';

// Créez un composant d'input personnalisé
const CustomInput = styled.div<{ isactive: string; iserror: string }>`
  display: flex;
  align-items: center;
  border: 1px solid
    ${(props) =>
      props.isactive ? 'black' : props.iserror ? 'red' : '#ccccccc3'};
  border-radius: 30px;
  padding: 7px;
  padding-left: 15px;
  transition: 0.2s ease;
`;

// Style pour le logo à gauche
const Logo = styled.img`
  width: 24px; /* Ajustez la taille selon vos besoins */
  margin-right: 8px; /* Espace entre le logo et l'input */
`;

// Style pour l'input
const Input = styled.input`
  flex: 1; /* Pour que l'input occupe tout l'espace disponible */
  border: none;
  outline: none;
  border-radius: 30px; /* Répétition du border-radius sur l'input */
  font-family: 'Montserrat';
  font-size: 16px;
  background-color: transparent;
  text-overflow: ellipsis;
  overflow: hidden;
`;

// Composant d'input complet
const InputWithLabel = ({
  logoSrc,
  ...props
}: { logoSrc: string } & React.InputHTMLAttributes<HTMLInputElement>) => {
  const [isActive, setIsActive] = useState<string>('false');
  const [isError, setIsError] = useState('false');

  const handleMouseDown = () => {
    setIsActive('true');
  };

  const handleFocus = () => {
    setIsActive('true');
  };

  const handleBlur = () => {
    setIsActive('false');
  };

  return (
    <CustomInput isactive={isActive} iserror={isError}>
      <Logo src={logoSrc} alt='Logo' />
      <Input
        onMouseDown={handleMouseDown}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...props}
      />
    </CustomInput>
  );
};

export default InputWithLabel;
