import React, { useEffect, useState } from 'react';
import LargeButton from '../Buttons/LargeButton';
import { ArrowRightCircleIcon } from '@heroicons/react/20/solid';
import Tabs from '../Tabs/Tabs';
import { ITabs } from '../../types/Tabs';
import SelectableCategoriesScoreList from './SelectableCategoriesScoreList';
import { axiosPrivateWithToken } from '../../api/axios';
import { useAppSelector } from '../../app/hook';
import { ICategory } from '../../types/Category';

interface ICategoriesChoicesProps {
  setSceneIndex: React.Dispatch<React.SetStateAction<number>>;
  selectedDimensions: string[];
  selectedCategories: (string | number)[];
  setSelectedCategories: React.Dispatch<
    React.SetStateAction<(string | number)[]>
  >;
}

const CategoriesChoices: React.FC<ICategoriesChoicesProps> = ({
  setSceneIndex,
  selectedDimensions,
  selectedCategories,
  setSelectedCategories,
}) => {
  const { token } = useAppSelector((state) => state.auth);
  const [tabs, setTabs] = useState<ITabs[]>([]);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [categoriesWithScore, setCategoriesWithScore] = useState<any>({});

  const [isLoading, setIsLoading] = useState(true);

  const handleNextScene = () => {
    setSceneIndex(3);
  };

  const buildTabs = () => {
    const buildingTabs: ITabs[] = [];

    selectedDimensions.map((dimension: string, index: number) => {
      buildingTabs.push({
        id: index,
        name: dimension,
      });
    });

    setTabs(buildingTabs);
  };

  const handleTabs = (index: number) => {
    setCurrentTabIndex(index);
  };

  const handleCategorySelection = (categoryId: number | string) => {
    const selectionsCopy: (string | number)[] = [...selectedCategories];

    const index = selectionsCopy.indexOf(categoryId);

    if (index !== -1) {
      // if categoryId already in array, delete it
      selectionsCopy.splice(index, 1);
    } else {
      // if categoryId isn't in array, add it
      selectionsCopy.push(categoryId);
    }

    setSelectedCategories(selectionsCopy);
  };

  const fetchCategories = async () => {
    const axiosPrivate = axiosPrivateWithToken(token);
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get(
        '/api/follow_up/configuration/categories_score',
        { params: { dimensions: selectedDimensions.join(';') } }
      );
      const data = response.data.categories;
      if (data) {
        setCategoriesWithScore(data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const autoRecommendations = () => {
    let recommendationsSelection: number[] | string[] = [];

    for (const dimensionName in categoriesWithScore) {
      categoriesWithScore[dimensionName].map((category: ICategory) => {
        if (category.score < 0) {
          recommendationsSelection.push(category.id as never);
        }
      });
    }
    setSelectedCategories(recommendationsSelection);
  };

  useEffect(() => {
    if (selectedDimensions.length > 0) {
      buildTabs();
      fetchCategories();
    }
  }, [selectedDimensions]);

  useEffect(() => {
    if (Object.keys(categoriesWithScore).length !== 0) {
      autoRecommendations();
    }
  }, [categoriesWithScore]);

  if (isLoading) return <></>;

  return (
    <div className='flex flex-col m-5 gap-10 justify-center sm:m-10 md:m-16 lg:gap-12'>
      <div className='flex flex-col gap-6 text-center md:justify-between md:flex-row md:gap-6 md:text-start'>
        <p className='text-2xl md:text-3xl lg:text-4xl font-bold'>
          Configuration des suivis
        </p>
        <LargeButton
          text='Commencer la configuration'
          RightIcon={ArrowRightCircleIcon}
          disabled={selectedCategories.length ? false : true}
          actionFunc={handleNextScene}
        />
      </div>
      <div className='ml-5 mr-5 md:ml-10 md:mr-10'>
        <p className='whitespace-pre-line text-sm md:text-base'>
          {`Vous avez choisi de vous améliorer sur les dimensions suivantes :`}
        </p>
        <ul className='list-disc mr-10 ml-10 mt-10'>
          {selectedDimensions.map((dimension) => (
            <li key={dimension} className='font-bold text-base md:text-lg'>
              {dimension}
            </li>
          ))}
        </ul>
      </div>
      <div className='ml-5 mr-5 md:ml-10 md:mr-10'>
        <p className='whitespace-pre-line text-sm md:text-base'>
          {`Chacune de ces dimensions est soigneusement divisée en catégories spécifiques pour une approche holistique de votre santé.`}
        </p>
        <p className='whitespace-pre-line text-sm md:text-base'>
          {`Choisissez les catégories où vous souhaitez progresser sur chaque dimension :`}
        </p>
        <div className='flex justify-center m-5 md:m-10'>
          <div className='w-full md:w-5/6'>
            {tabs.length && Object.keys(categoriesWithScore).length !== 0 ? (
              <>
                <Tabs
                  tabs={tabs}
                  handleTabs={handleTabs}
                  currentTabIndex={currentTabIndex}
                />
                <SelectableCategoriesScoreList
                  categories={
                    categoriesWithScore[tabs[currentTabIndex]['name']]
                  }
                  selectedCategories={selectedCategories}
                  handleCategorySelection={handleCategorySelection}
                />
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoriesChoices;
