import Reac, { Fragment } from 'react';

import { Popover, Transition } from '@headlessui/react';

import defaultProfilPic from '../../assets/svg/icon/user.svg';

const solutions = [{ name: 'Profil de forme', href: '/profil' }];

interface IAvatarFlyoutProps {
  avatar: string | null;
  handleDisconnect: (...args: any) => any;
}

const AvatarFlyout: React.FC<IAvatarFlyoutProps> = ({
  avatar,
  handleDisconnect,
}) => {
  return (
    <Popover className='relative'>
      <Popover.Button>
        <img src={avatar || defaultProfilPic} className='rounded-full' />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-200'
        enterFrom='opacity-0 translate-y-1'
        enterTo='opacity-100 translate-y-0'
        leave='transition ease-in duration-150'
        leaveFrom='opacity-100 translate-y-0'
        leaveTo='opacity-0 translate-y-1'
      >
        <Popover.Panel className='absolute z-10 mt-5 flex max-w-min top-12 right-0 '>
          <div className='w-40 shrink rounded-xl bg-white p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5'>
            {/* {solutions.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className='block p-2 hover:text-sky-600 text-end'
              >
                {item.name}
              </a>
            ))} */}
            <p
              key={'disconnect'}
              className='block p-2 hover:text-sky-600 text-end cursor-pointer'
              onClick={handleDisconnect}
            >
              Déconnexion
            </p>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default AvatarFlyout;
