import { SVGProps, useState } from 'react';

import { ReactComponent as LinkedinIcon } from '../assets/svg/icon/linkedin.svg';
import { ReactComponent as TwitterIcon } from '../assets/svg/icon/twitter.svg';
import { ReactComponent as InstagramIcon } from '../assets/svg/icon/instagram.svg';

const iconComponents: {
  [key: string]: React.FunctionComponent<SVGProps<SVGSVGElement>>;
} = {
  Linkedin: LinkedinIcon,
  Instagram: InstagramIcon,
  Twitter: TwitterIcon,
};

const navigation = {
  main: [
    { name: 'À propos de nous', href: '#' },
    { name: 'Contactez-nous', href: '#' },
    { name: 'Mentions légales', href: '#' },
    { name: 'Données personnelles', href: '#' },
  ],
  social: [
    {
      name: 'Linkedin',
      href: 'https://www.linkedin.com/company/one-prev/',
    },
    {
      name: 'Instagram',
      href: '#',
    },
    {
      name: 'Twitter',
      href: '#',
    },
  ],
};

function Footer() {
  const currentYear: number = new Date().getFullYear();

  return (
    <footer className='bg-white'>
      <div className='mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8'>
        <nav
          className='-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12'
          aria-label='Footer'
        >
          {navigation.main.map((item) => (
            <div key={item.name} className='pb-6'>
              <a
                href={item.href}
                className='text-sm leading-6 text-gray-600 hover:text-gray-900'
              >
                {item.name}
              </a>
            </div>
          ))}
        </nav>
        <div className='mt-10 flex justify-center space-x-10'>
          {navigation.social.map((item) => {
            const IconComponent = iconComponents[item.name];

            return (
              <a key={item.name} href={item.href} target='_blank'>
                <span className='sr-only'>{item.name}</span>
                <IconComponent
                  className='h-6 w-6 fill-gray-400 hover:fill-gray-500'
                  aria-hidden='true'
                />
              </a>
            );
          })}
        </div>
        <p className='mt-10 text-center text-xs leading-5 text-gray-500'>
          &copy; {currentYear} One Prev, Inc. All rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
