export const DIRECTION = {
  FORWARD: 'forward',
  BACKWARD: 'backward',
} as const;

export const FORM_STATUS = {
  FETCHING_FORM: -1,
  READY: 0,
  IN_PROGRESS: 1,
  SENDING_ANSWER: 2,
  FINISH: 3,
} as const;
