import React from 'react';
import { IButtonProps } from '../../types/Button';
import { BG_COLOR_VARIANTS } from '../../constants/TailwindColors';

const LargeButton: React.FC<IButtonProps> = ({
  text,
  color = BG_COLOR_VARIANTS['sky']['default'],
  disabled = false,
  LeftIcon,
  RightIcon,
  actionFunc,
}) => {
  return (
    <button
      type='button'
      onClick={actionFunc}
      disabled={disabled}
      className={`${color} text-sm px-2 py-2 rounded-[2rem] md:rounded-full md:px-4 md:py-2 md:text-md xl:px-6 xl:py-2.5 xl:text-xl font-semibold text-white shadow-sm flex gap-2 items-center justify-center disabled:bg-slate-500 transition duration-300`}
    >
      {LeftIcon && <LeftIcon className='h-9 w-9' aria-hidden='true' />}
      {text}
      {RightIcon && <RightIcon className='h-9 w-9' aria-hidden='true' />}
    </button>
  );
};

export default LargeButton;
