import React from 'react';

import { ReactComponent as HappyIcon } from '../../assets/svg/icon/happy_smiley.svg';
import { ReactComponent as SmileIcon } from '../../assets/svg/icon/smile_smiley.svg';
import { ReactComponent as NeutralIcon } from '../../assets/svg/icon/neutral_smiley.svg';
import { ReactComponent as SadIcon } from '../../assets/svg/icon/sad_smiley.svg';
import { ReactComponent as CryIcon } from '../../assets/svg/icon/cry_smiley.svg';
import { ICategory } from '../../types/Category';

interface ICategoriesCard {
  id: number | string;
  name: string;
  score: number;
  selected: boolean;
  handleCategorySelection: (categoryId: number | string) => void;
}

const CategoriesCard: React.FC<ICategoriesCard> = ({
  id,
  name,
  score,
  selected,
  handleCategorySelection,
}) => {
  const getIconByScore = () => {
    switch (score) {
      case -3:
        return (
          <CryIcon className='w-[1.3rem] h-[1.3rem] md:h-[1.8rem] md:w-[1.8rem] ' />
        );
      case -2:
        return (
          <SadIcon className='w-[1.3rem] h-[1.3rem] md:h-[1.8rem] md:w-[1.8rem] ' />
        );
      case -1:
        return (
          <SadIcon className='w-[1.3rem] h-[1.3rem] md:h-[1.8rem] md:w-[1.8rem] ' />
        );
      case 0:
        return (
          <NeutralIcon className='w-[1.3rem] h-[1.3rem] md:h-[1.8rem] md:w-[1.8rem]' />
        );
      case 1:
        return (
          <SmileIcon className='w-[1.3rem] h-[1.3rem] md:h-[1.8rem] md:w-[1.8rem] ' />
        );
      case 2:
        return (
          <HappyIcon className='w-[1.3rem] h-[1.3rem] md:h-[1.8rem] md:w-[1.8rem] ' />
        );
      default:
        return null;
    }
  };

  return (
    <div
      className={`border p-2 md:p-4 rounded-xl mt-2 mb-2 flex justify-center md:justify-between items-center pl-8 pr-8 hover:cursor-pointer hover:bg-slate-50 ${
        selected ? 'outline outline-offset-2 outline-2 outline-sky-600' : ''
      }`}
      onClick={() => handleCategorySelection(id)}
    >
      <div className='flex flex-col md:flex-row gap-2 md:gap-10 items-center'>
        <div className='flex flex-col items-center text-xs gap-1'>
          Votre profil : {getIconByScore()}
        </div>
        <p className='text-sm md:text:base font-bold'>{name}</p>
      </div>
      <input
        aria-describedby='comments-description'
        name='comments'
        type='checkbox'
        checked={selected}
        onChange={() => handleCategorySelection(id)}
        className='hidden md:block h-5 w-5 rounded border-gray-300 text-sky-600 focus:ring-sky-600'
      />
    </div>
  );
};

interface ISelectableCategoriesScoreListProps {
  categories: ICategory[];
  selectedCategories: (string | number)[];
  handleCategorySelection: (categoryId: number | string) => void;
}

const SelectableCategoriesScoreList: React.FC<
  ISelectableCategoriesScoreListProps
> = ({ categories, selectedCategories, handleCategorySelection }) => {
  return (
    <div className='p-4 mt-2 flex flex-col h-96 overflow-y-scroll border-b'>
      {categories.map((category: ICategory) => (
        <CategoriesCard
          key={category.id}
          id={category.id}
          name={category.name}
          score={category.score}
          selected={selectedCategories.includes(category.id as never)}
          handleCategorySelection={handleCategorySelection}
        />
      ))}
    </div>
  );
};

export default SelectableCategoriesScoreList;
