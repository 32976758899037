import { Fragment, useState } from 'react';
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react';
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
} from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

import OnePrevLogoVertical from '../assets/img/OnePrev_logo-vertical.webp';
import Avatar from './Avatar/Avatar';
import { useAppDispatch } from '../app/hook';
import { logout } from '../features/auth/authSlice';

const products = [
  {
    name: 'Analytics',
    description: 'Get a better understanding of your traffic',
    href: '#',
    icon: ChartPieIcon,
  },
  {
    name: 'Engagement',
    description: 'Speak directly to your customers',
    href: '#',
    icon: CursorArrowRaysIcon,
  },
  {
    name: 'Security',
    description: 'Your customers’ data will be safe and secure',
    href: '#',
    icon: FingerPrintIcon,
  },
  {
    name: 'Integrations',
    description: 'Connect with third-party tools',
    href: '#',
    icon: SquaresPlusIcon,
  },
  {
    name: 'Automations',
    description: 'Build strategic funnels that will convert',
    href: '#',
    icon: ArrowPathIcon,
  },
];
const callsToAction = [
  { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
  { name: 'Contact sales', href: '#', icon: PhoneIcon },
];
const company = [
  { name: 'À propos de nous', href: '#' },
  { name: 'Contacter le support', href: '#' },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface INavBarProps {
  authLoading: boolean;
  isAuthenticated: boolean;
}

const LoginButton = () => {
  return (
    <div className='hidden lg:flex lg:flex-1 lg:justify-end'>
      <Link
        to='/connexion'
        className='text-sm font-semibold leading-6 text-gray-900'
      >
        Se connecter <span aria-hidden='true'>&rarr;</span>
      </Link>
    </div>
  );
};

const AvatarSkeleton = () => (
  <div className='hidden lg:flex lg:flex-1 lg:justify-end'>
    <div className=' animate-pulse w-14 h-14 mr-3 bg-gray-200 rounded-full' />
  </div>
);

const NavBar: React.FC<INavBarProps> = ({ authLoading, isAuthenticated }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const dispatch = useAppDispatch();

  const handleDisconnect = () => {
    dispatch(logout());
    window.location.href = `${process.env.REACT_APP_BASE_URL_FRONT}/`;
  };

  return (
    <header className='bg-white border-b'>
      <nav
        className='mx-auto flex items-center justify-between p-6 lg:px-8'
        aria-label='Global'
      >
        <div className='flex lg:flex-1'>
          <a href='/' className='-m-1.5 p-1.5'>
            <span className='sr-only'>One Prev</span>
            <img className='h-12 w-auto' src={OnePrevLogoVertical} alt='' />
          </a>
        </div>
        <div className='flex lg:hidden'>
          <button
            type='button'
            className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700'
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className='sr-only'>Open main menu</span>
            <Bars3Icon className='h-6 w-6' aria-hidden='true' />
          </button>
        </div>
        <Popover.Group className='hidden lg:flex lg:gap-x-12'>
          {/* <Popover className='relative'>
            <Popover.Button className='flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900'>
              Produit
              <ChevronDownIcon
                className='h-5 w-5 flex-none text-gray-400'
                aria-hidden='true'
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel className='absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5'>
                <div className='p-4'>
                  {products.map((item) => (
                    <div
                      key={item.name}
                      className='group relative flex gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50'
                    >
                      <div className='mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white'>
                        <item.icon
                          className='h-6 w-6 text-gray-600 group-hover:text-indigo-600'
                          aria-hidden='true'
                        />
                      </div>
                      <div className='flex-auto'>
                        <a
                          href={item.href}
                          className='block font-semibold text-gray-900'
                        >
                          {item.name}
                          <span className='absolute inset-0' />
                        </a>
                        <p className='mt-1 text-gray-600'>{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className='grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50'>
                  {callsToAction.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className='flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100'
                    >
                      <item.icon
                        className='h-5 w-5 flex-none text-gray-400'
                        aria-hidden='true'
                      />
                      {item.name}
                    </a>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover> */}

          {isAuthenticated && (
            <>
              <Link
                to='/screening'
                className='text-sm font-semibold leading-6 text-gray-900'
              >
                Screening
              </Link>
            </>
          )}

          {isAuthenticated && (
            <>
              <Link
                to='/profil'
                className='text-sm font-semibold leading-6 text-gray-900'
              >
                Profil de forme
              </Link>
            </>
          )}

          {isAuthenticated && (
            <>
              <Link
                to='/follow_ups'
                className='text-sm font-semibold leading-6 text-gray-900'
              >
                Follow ups
              </Link>
            </>
          )}
        </Popover.Group>
        {authLoading ? (
          <AvatarSkeleton />
        ) : !isAuthenticated ? (
          <LoginButton />
        ) : (
          <div className='hidden lg:flex lg:flex-1 lg:justify-end'>
            <Avatar
              isAuthenticated={isAuthenticated}
              isLoading={authLoading}
              handleDisconnect={handleDisconnect}
            />
          </div>
        )}
      </nav>
      <Dialog
        as='div'
        className='lg:hidden'
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className='fixed inset-0 z-10' />
        <Dialog.Panel className='fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10'>
          <div className='flex items-center justify-between'>
            <a href='#' className='-m-1.5 p-1.5'>
              <span className='sr-only'>One Prev</span>
              <img className='h-8 w-auto' src={OnePrevLogoVertical} alt='' />
            </a>
            <button
              type='button'
              className='-m-2.5 rounded-md p-2.5 text-gray-700'
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className='sr-only'>Close menu</span>
              <XMarkIcon className='h-6 w-6' aria-hidden='true' />
            </button>
          </div>
          <div className='mt-6 flow-root'>
            <div className='-my-6 divide-y divide-gray-500/10'>
              <div className='space-y-2 py-6'>
                {isAuthenticated && (
                  <div className='-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 cursor-pointer'>
                    <Link
                      to={'/profil'}
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      Profil de forme
                    </Link>
                  </div>
                )}

                {isAuthenticated && (
                  <div className='-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 cursor-pointer'>
                    <Link
                      to={'/follow_ups'}
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      Follow ups
                    </Link>
                  </div>
                )}

                {/* <Disclosure as='div' className='-mx-3'>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className='flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'>
                        Company
                        <ChevronDownIcon
                          className={classNames(
                            open ? 'rotate-180' : '',
                            'h-5 w-5 flex-none'
                          )}
                          aria-hidden='true'
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className='mt-2 space-y-2'>
                        {company.map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as='a'
                            href={item.href}
                            className='block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50'
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure> */}
              </div>
              <div className='py-6'>
                {authLoading ? (
                  <AvatarSkeleton />
                ) : !isAuthenticated ? (
                  <LoginButton />
                ) : (
                  <div className='hidden lg:flex lg:flex-1 lg:justify-end'>
                    <Avatar
                      isAuthenticated={isAuthenticated}
                      isLoading={authLoading}
                      handleDisconnect={handleDisconnect}
                    />
                  </div>
                )}

                {isAuthenticated ? (
                  <div
                    className='-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 cursor-pointer'
                    onClick={() => {
                      setMobileMenuOpen(false);
                      handleDisconnect();
                    }}
                  >
                    Déconnexion
                  </div>
                ) : (
                  <div className='-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 cursor-pointer'>
                    <Link to={'/connexion'}>Se connecter</Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};

export default NavBar;
