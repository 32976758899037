import React from 'react';
import { useAppSelector } from '../app/hook';

const HomeSkeleton = () => {
  return (
    <div className='flex w-screen h-auto m-10 justify-center'>
      <div className=' animate-pulse w-3/4 h-80 mr-3 bg-gray-200 ' />
    </div>
  );
};

const Home = () => {
  const { username } = useAppSelector((state) => state.user);
  const { isLoading } = useAppSelector((state) => state.auth);

  return (
    <div className='h-[32rem]'>
      {isLoading ? (
        <></>
      ) : (
        <div className='mt-12 ml-16 mr-16'>
          <h1 className='text-2xl font-bold'>Bonjour {username}.</h1>
          <p>Ceci est la page d'accueil One Prev</p>
        </div>
      )}
    </div>
  );
};

export default Home;
