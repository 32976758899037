import React, { useEffect } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import Cookies from 'js-cookie';

import './App.css';
import Login from './pages/Login';
import NotMatch from './pages/NoMatch';
import Home from './pages/Home';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import { ThemeProvider } from 'styled-components';
import { theme } from './styles/Theme';
import Register from './pages/Register';
import { useAppDispatch, useAppSelector } from './app/hook';
import { notAuthenticated, setUser } from './features/auth/authSlice';
import { getUserData } from './features/user/userSlice';
import Screening from './pages/Screening';
import Profile from './pages/Profile';
import FollowUpConfiguration from './pages/FollowUpConfiguration';
import FollowUpForm from './pages/FollowUpForm';
import ProtectedRoute from './ProtectedRoute';
import FollowUps from './pages/FollowUps';

const WithNav = (WrappedComponent: React.FC) => {
  const { isAuthenticated, isLoading } = useAppSelector((state) => state.auth);

  return (
    <>
      <NavBar isAuthenticated={isAuthenticated} authLoading={isLoading} />
      <WrappedComponent />
      <Footer />
    </>
  );
};

function App() {
  const dispatch = useAppDispatch();
  const { isLoading, isAuthenticated } = useAppSelector((state) => state.auth);
  const { isSuccess, isError } = useAppSelector((state) => state.user);
  const token = Cookies.get('service_token') || localStorage.getItem('token');

  useEffect(() => {
    if (token) {
      dispatch(getUserData(token));
    } else {
      dispatch(notAuthenticated({}));
    }
  }, []);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setUser(token));
    } else if (isError) {
      dispatch(notAuthenticated({}));
    }
  }, [isSuccess, isLoading, isError]);

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path='' element={WithNav(Home)} />
        <Route path='connexion' element={<Login />} />
        <Route path='inscription' element={<Register />} />
        <Route
          path='screening'
          element={<ProtectedRoute element={<Screening />} />}
        />
        <Route
          path='follow_up/form/:id'
          element={<ProtectedRoute element={<FollowUpForm />} />}
        />
        <Route
          path='profil'
          element={<ProtectedRoute element={WithNav(Profile)} />}
        />
        <Route
          path='follow_ups'
          element={<ProtectedRoute element={WithNav(FollowUps)} />}
        />
        <Route
          path='fu_configuration'
          element={<ProtectedRoute element={<FollowUpConfiguration />} />}
        />
        <Route path='*' element={<NotMatch />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
