import React, { useEffect } from 'react';
import { useAppSelector } from './app/hook';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ element }: { element: React.ReactElement }) => {
  const { isAuthenticated, isLoading } = useAppSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      navigate('/connexion', { state: { path: location.pathname } });
    }
  }, [isAuthenticated, isLoading]);

  return isAuthenticated ? element : null;
};

export default ProtectedRoute;
