import axios, { axiosPrivateWithToken } from '../../api/axios';
import { IUserDataAuth } from '../../types/Users';

const getUserData = async (token: string) => {
  const axiosFileInstance = axiosPrivateWithToken(token);

  const response = await axiosFileInstance.get('/api/users/me');

  return response.data;
};

const userService = {
  getUserData,
};

export default userService;
