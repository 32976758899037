import React, { useEffect, useState } from 'react';
import LargeButton from '../components/Buttons/LargeButton';
import { Link, useNavigate } from 'react-router-dom';

import { useAppSelector } from '../app/hook';
import { FORM_STATUS } from '../constants/Form';
import Form from '../components/Form/Form';

const STATUS = {
  ...FORM_STATUS,
};

interface IStartSceneProps {
  setFormStatus: React.Dispatch<React.SetStateAction<number>>;
}

const StartScene: React.FC<IStartSceneProps> = ({ setFormStatus }) => {
  const handleStart = () => {
    setFormStatus(STATUS['IN_PROGRESS']);
  };

  return (
    <div className='flex justify-center items-center h-screen w-screen flex-col'>
      <div className='flex justify-center flex-col w-3/5 gap-7 text-center items-center'>
        <h1 className='text-4xl font-bold my-6'>
          Votre santé est importante !
        </h1>
        <div>
          <p>
            Vous êtes sur le point de remplir notre questionnaire de profilage.
            Nous avons besoin de ces informations pour vous connaître. Veuillez
            répondre honnêtement pour que nous puissions vous offrir un
            accompagnement personnalisé.
          </p>
          <p>Votre précision est essentielle pour vous aider au mieux.</p>
        </div>
        <div className='flex flex-col w-2/5'>
          <LargeButton text='Commencer' actionFunc={handleStart} />
        </div>
      </div>
    </div>
  );
};

const ScreenedScene: React.FC = () => {
  const navigate = useNavigate();

  const navigateToProfilePage = () => {
    navigate('/profil');
  };

  return (
    <div className='flex justify-center items-center h-screen w-screen flex-col'>
      <div className='flex justify-center flex-col w-3/5 gap-7 text-center items-center'>
        <h1 className='text-4xl font-bold my-6'>Vous avez déjà répondu</h1>
        <div>
          <p>
            Vous avez déjà rempli notre questionnaire de profilage. Vous pouvez
            trouvez votre état de forme sur chacune des dimensions en allant
            consulter votre page profil.
          </p>
        </div>
        <div className='flex flex-col w-2/5'>
          <LargeButton text='Mon profil' actionFunc={navigateToProfilePage} />
        </div>
      </div>
    </div>
  );
};

const LoadingScene: React.FC = () => {
  return (
    <div className='flex justify-center items-center h-screen w-screen flex-col'>
      <div className='flex justify-center flex-col w-3/5 gap-7 text-center items-center'>
        <div className='animate-pulse w-9/12 h-9 mr-3 bg-gray-200 rounded-full' />
        <div className='w-full'>
          <div className='animate-pulse h-4 bg-gray-200 rounded-full dark:bg-gray-700 mb-4'></div>
          <div className='animate-pulse h-3.5 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5'></div>
          <div className='animate-pulse h-3.5 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5'></div>
          <div className='animate-pulse h-3.5 bg-gray-200 rounded-full dark:bg-gray-700'></div>
        </div>
        <div className='flex flex-col items-center w-2/5'>
          <div className='animate-pulse w-48 h-10 mr-3 bg-gray-200 rounded-full' />
        </div>
      </div>
    </div>
  );
};

const Screening = () => {
  const [screeningStatus, setScreeningStatus] = useState<number>(
    STATUS['FETCHING_FORM']
  );
  const [formResponseStatus, setFormResponseStatus] = useState(0);
  const { isScreened } = useAppSelector((state) => state.user);
  const navigate = useNavigate();

  const formEndPoint = '/api/screening/form';
  const answerEndPoint = '/api/screening/answer';

  useEffect(() => {
    if (screeningStatus === STATUS['FINISH']) {
      navigate('/fu_configuration');
    }
  }, [screeningStatus]);

  if (screeningStatus !== STATUS['FINISH'] && isScreened)
    return <ScreenedScene />;

  return (
    <div className='h-screen w-screen overflow-y-hidden'>
      {screeningStatus === STATUS['FETCHING_FORM'] && <LoadingScene />}
      {screeningStatus === STATUS['READY'] && (
        <StartScene setFormStatus={setScreeningStatus} />
      )}
      <Form
        formEndpoint={formEndPoint}
        answerEndpoint={answerEndPoint}
        formStatus={screeningStatus}
        setFormStatus={setScreeningStatus}
        setFormResponseStatus={setFormResponseStatus}
      />
    </div>
  );
};

export default Screening;
