import React from 'react';
import styled from 'styled-components';

interface IButtonSCProps {
  text: string;
  bgColor?: string;
  color?: string;
  iconSrc?: string;
  hoverColor?: string;
  activeColor?: string;
  actionFunc?: (...args: any) => any;
}

interface ICustomButtonProps {
  bgcolor?: string;
  hovercolor?: string;
  activecolor?: string;
}

const Logo = styled.img`
  width: 24px;
  margin-right: 8px;
`;

const CustomButton = styled.button<ICustomButtonProps>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 2px;
  padding: 15px;
  width: 100%;
  border-radius: 30px;
  background-color: ${(props) => props.bgcolor || '#1c677c'};
  color: ${(props) => props.color || 'white'};
  font-family: 'Montserrat';
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.hovercolor || '#217a92'};
  }

  &:active {
    background-color: ${(props) => props.activecolor || '#124350'};
  }
`;

const ButtonSC: React.FC<IButtonSCProps> = ({
  text,
  bgColor,
  color,
  iconSrc,
  hoverColor,
  activeColor,
  actionFunc,
}) => {
  return (
    <CustomButton
      bgcolor={bgColor}
      color={color}
      hovercolor={hoverColor}
      activecolor={activeColor}
      onClick={actionFunc}
    >
      {iconSrc ? <Logo src={iconSrc} /> : <></>}
      {text}
      {iconSrc ? <div></div> : <></>}
    </CustomButton>
  );
};

export default ButtonSC;
