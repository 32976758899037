import React from 'react';
import { useAppSelector } from '../../app/hook';
import AvatarFlyout from '../Menus/AvatarFlyout';

interface IAvatarProps {
  isAuthenticated: boolean;
  isLoading: boolean;
  handleDisconnect: (...args: any) => any;
}

const AvatarSkeleton = () => (
  <div className=' animate-pulse w-14 h-14 mr-3 bg-gray-200 rounded-full' />
);

const Avatar: React.FC<IAvatarProps> = ({
  isAuthenticated,
  isLoading,
  handleDisconnect,
}) => {
  const { avatar } = useAppSelector((state) => state.user);
  return isLoading ? (
    <AvatarSkeleton />
  ) : isAuthenticated ? (
    <div className='w-14 h-14 mr-3'>
      <AvatarFlyout avatar={avatar} handleDisconnect={handleDisconnect} />
    </div>
  ) : (
    <></>
  );
};

export default Avatar;
