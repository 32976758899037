interface IIconButtonProps {
  Icon: React.ForwardRefExoticComponent<
    Omit<React.SVGProps<SVGSVGElement>, 'ref'>
  >;
  disabled?: boolean;
  actionFunc: (...args: any) => any;
}

const IconButton: React.FC<IIconButtonProps> = ({
  Icon,
  disabled,
  actionFunc,
}) => {
  return (
    <button
      type='button'
      onClick={actionFunc}
      disabled={disabled}
      className='disabled:bg-slate-500 rounded-full bg-sky-600 p-1.5 text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600'
    >
      <Icon className='h-5 w-5' aria-hidden='true' />
    </button>
  );
};

export default IconButton;
