import React from 'react';
import { formatDistance, subDays } from 'date-fns';
import { fr } from 'date-fns/locale';

import { ReactComponent as TargetIcon } from '../../assets/svg/icon/target.svg';
import { IFollowUp } from '../../types/FollowUp';

interface IFollowUpCard {
  followUp: IFollowUp;
}

const FollowUpCard: React.FC<IFollowUpCard> = ({ followUp }) => {
  return (
    <div className='flex flex-col gap-3 md:gap-5 p-3 md:p-4 bg-gray-100 rounded-xl'>
      <h1 className={`text-sm md:text-base font-bold text-sky-600 text-center`}>
        {followUp.category}
      </h1>
      <div className='self-center bg-white p-3 rounded-full'>
        <TargetIcon className='h-[3rem] w-[3rem]' />
      </div>
      <div className='self-center flex gap-1 font-bold'>
        <span>Score : </span>
        <p>
          {followUp.goal_achieved ? followUp.goal_achieved : 0} sur{' '}
          {followUp.total_response ? followUp.total_response : 0}
        </p>
      </div>
      <div className='flex flex-col gap-1 justify-center text-center'>
        <div className=''>
          <span className='text-xs font-bold'>Objectif :</span>
          <p className='text-xs'>{followUp.target.target_text}</p>
        </div>
        <div className=''>
          <span className='text-xs font-bold'>Fréquence :</span>
          <p className='text-xs'>{followUp.frequency}</p>
        </div>
        <div className=''>
          <span className='text-xs font-bold'>Prochain rappel :</span>
          <p className='text-xs'>
            {formatDistance(new Date(followUp.next_reminder), new Date(), {
              addSuffix: true,
              locale: fr,
            })}
          </p>
        </div>
      </div>
    </div>
  );
};

export default FollowUpCard;
