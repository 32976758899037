import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userService from './userService';
import { IUserDataAuth } from '../../types/Users';
import Cookies from 'js-cookie';

// Define a type for the slice state
interface IUserState {
  id: string;
  avatar: string | null;
  username: string;
  email: string;
  isScreened: boolean | null;
  isAdmin: boolean | null;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  message: string;
}

// Define the initial state using that type
const initialState: IUserState = {
  id: '',
  avatar: null,
  username: '',
  email: '',
  isScreened: null,
  isAdmin: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

export const getUserData = createAsyncThunk(
  'user/me',
  async (token: string, thunkAPI) => {
    try {
      return await userService.getUserData(token);
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error.message || error.toString();

      console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = '';
    },
    userScreened: (state) => {
      state.isScreened = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.id = action.payload.id;
        state.avatar = action.payload.avatar;
        state.isScreened = action.payload.isScreened;
        state.isAdmin = action.payload.isAdmin;
        state.username = action.payload.username;
        state.email = action.payload.email;
      })
      .addCase(getUserData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload as string;
        localStorage.removeItem('token');
        Cookies.remove('service_token');
      });
  },
});

export const { reset, userScreened } = userSlice.actions;
export default userSlice.reducer;
