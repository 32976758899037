import React, { useState } from 'react';
import { ReactComponent as NutritionIcon } from '../../assets/svg/icon/apple.svg';
import { ReactComponent as PhysicalActivityIcon } from '../../assets/svg/icon/walk.svg';
import { ReactComponent as AddictionIcon } from '../../assets/svg/icon/wine.svg';

import { CheckCircleIcon } from '@heroicons/react/20/solid';

interface IRecommendationCardProps {
  id: number;
  dimension: string;
  score: number;
  score_type: string;
  recommendation: boolean;
  handleDimensionSelection: (dimension: string) => void;
}

const description: any = {
  Nutrition:
    'Une alimentation équilibrée est essentielle pour fournir les nutriments au bon fonctionnement du corps, prévenir les carences et réduire les risques de maladies chroniques.',
  'Activité physique':
    "L'exercice régulier renforce les muscles, améliore la santé cardiaque, aide à maintenir un poids sain et contribue à la santé mentale.",
  Addiction:
    'Éviter les comportements addictifs limite les dommages physiques, mentaux et sociaux, préservant la santé physique et les relations interpersonnelles.',
};

const borderColor: any = {
  P1: 'border-amber-600 border-solid border-4 scale-105',
  P2: 'border-red-600 border-solid border-4 scale-105',
  P3: 'border-sky-600 border-solid border-4 scale-105',
  P4: 'border-emerald-600 border-solid border-4 scale-105',
};

const textColor: any = {
  P1: 'text-amber-600',
  P2: 'text-red-600',
  P3: 'text-sky-600',
  P4: 'text-emerald-600',
};

const RecommendationCard: React.FC<IRecommendationCardProps> = ({
  id,
  dimension,
  score,
  score_type,
  recommendation,
  handleDimensionSelection,
}) => {
  const [isSelected, setIsSelected] = useState(false);

  const getIconByDimensionName = () => {
    switch (dimension) {
      case 'Nutrition':
        return (
          <NutritionIcon className='h-[4rem] w-[4rem] md:h-[5rem] md:w-[5rem] p-2 rounded-3xl' />
        );
      case 'Activité physique':
        return (
          <PhysicalActivityIcon className='h-[4rem] w-[4rem] md:h-[5rem] md:w-[5rem] p-2 rounded-3xl' />
        );
      case 'Addiction':
        return (
          <AddictionIcon className='h-[4rem] w-[4rem] md:h-[5rem] md:w-[5rem] p-2 rounded-3xl' />
        );
      default:
        return null;
    }
  };

  const handleSelect = () => {
    setIsSelected(!isSelected);
    handleDimensionSelection(dimension);
  };

  return (
    <div
      onClick={handleSelect}
      className={`min-h-[20rem] md:min-h-[22rem] p-3 md:p-4 hover:cursor-pointer transition duration-200 rounded-xl bg-gray-100 w-64 md:w-80 flex flex-col gap-2 justify-between ${
        isSelected
          ? borderColor[score_type]
          : 'border-transparent border-solid border-4'
      }`}
    >
      <div className='flex flex-col gap-2 text-center'>
        <div className={`font-black md:text-xl ${textColor[score_type]}`}>
          {dimension}
        </div>
        <div className='font-bold text-m'>{score_type}</div>
        <div className='self-center bg-white p-3 rounded-full'>
          {getIconByDimensionName()}
        </div>
        <div className='text-xs md:text-sm mt-6'>{description[dimension]}</div>
      </div>
      {isSelected ? (
        <div className='self-center mt-3'>
          <CheckCircleIcon className={`h-6 w-6 ${textColor[score_type]}`} />
        </div>
      ) : (
        <div className='h-6 w-6 mt-3'></div>
      )}
    </div>
  );
};

export default RecommendationCard;
