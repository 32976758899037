import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../app/hook';
import { axiosPrivateWithToken } from '../../api/axios';
import RecommendationCard from './RecommendationCard';
import LargeButton from '../Buttons/LargeButton';

import { ArrowRightCircleIcon } from '@heroicons/react/20/solid';

interface IDimensionChoicesSceneProps {
  dimensionsSelection: string[];
  setSceneIndex: React.Dispatch<React.SetStateAction<number>>;
  setDimensionsSelection: React.Dispatch<React.SetStateAction<string[]>>;
}

interface IDimensionData {
  id: number;
  dimension: string;
  score: number;
  score_type: string;
  user_id: string;
  last_update: string;
  recommendation: boolean;
}

const DimensionsChoicesScene: React.FC<IDimensionChoicesSceneProps> = ({
  dimensionsSelection,
  setSceneIndex,
  setDimensionsSelection,
}) => {
  const { token, isAuthenticated } = useAppSelector((state) => state.auth);
  const [recommandedDimensions, setRecommandedDimensions] = useState<
    IDimensionData[]
  >([]);
  const [otherDimensions, setOtherDimensions] = useState<IDimensionData[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleNextScene = () => {
    setSceneIndex(2);
  };

  const handleDimensionSelection = (dimension: string) => {
    const isDimensionSelected = dimensionsSelection.includes(dimension);

    if (isDimensionSelected) {
      const updatedDimensionsSelection = dimensionsSelection.filter(
        (selectedDimension) => selectedDimension !== dimension
      );

      setDimensionsSelection(updatedDimensionsSelection);
    } else {
      setDimensionsSelection([...dimensionsSelection, dimension]);
    }
  };

  const fetchDimensionsRecommandations = async () => {
    const axiosPrivate = axiosPrivateWithToken(token);
    try {
      const response = await axiosPrivate.get(
        '/api/follow_up/configuration/dimension_recommendation'
      );
      const data = response.data.dimensions_score;
      if (data) {
        const recommendations: IDimensionData[] = [];
        const others: IDimensionData[] = [];
        data.map((dimension: IDimensionData) => {
          if (dimension.recommendation) {
            recommendations.push(dimension);
          } else {
            others.push(dimension);
          }
        });
        setRecommandedDimensions(recommendations);
        setOtherDimensions(others);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      isAuthenticated &&
      !recommandedDimensions.length &&
      !otherDimensions.length
    ) {
      fetchDimensionsRecommandations();
    }
  }, []);

  if (isLoading) {
    return <></>;
  }

  return (
    <div className='flex flex-col m-5 gap-10 justify-center sm:m-10 md:m-16 lg:gap-12'>
      <div className='flex flex-col gap-6 text-center md:justify-between md:flex-row md:gap-6 md:text-start'>
        <p className='text-2xl md:text-3xl lg:text-4xl font-bold'>
          Quelles dimensions souhaiteriez-vous améliorer ?
        </p>
        <LargeButton
          text="J'ai terminé ma sélection"
          RightIcon={ArrowRightCircleIcon}
          disabled={dimensionsSelection.length ? false : true}
          actionFunc={handleNextScene}
        />
      </div>
      <p className='whitespace-pre-line text-sm md:text-base'>
        {
          'Félicitations ! Grâce à vos réponses, nous avons identifié vos faiblesses ainsi que vos points forts.\nVeuillez maintenant choisir les dimensions sur lesquelles vous souhaitez vous concentrer pour progresser.'
        }
      </p>
      <div className='flex flex-col gap-6'>
        <p className='font-bold text-xl md:text-2xl'>
          Vos faiblesses majeurs :
        </p>
        <div className='flex flex-col self-center md:flex md:flex-row md:justify-center md:flex-wrap gap-7 lg:gap-14 mt-5 mb-5'>
          {recommandedDimensions.length ? (
            recommandedDimensions.map((data) => (
              <RecommendationCard
                key={data.id}
                dimension={data.dimension}
                score={data.score}
                score_type={data.score_type}
                recommendation={data.recommendation}
                id={data.id}
                handleDimensionSelection={handleDimensionSelection}
              />
            ))
          ) : (
            <div className='col-span-full'>
              <p className='font-bold text-xl md:text-2xl text-gray-600 mr-5 ml-5 md:mr-20 md:ml-20'>
                Bravo, vous n'avez pas de faiblesse ! Mais vous pouvez quand
                même progresser sur vos forces pour être encore plus fort !
              </p>
            </div>
          )}
        </div>
      </div>
      <div className='flex flex-col gap-6'>
        <p className='font-bold text-xl md:text-2xl'>Les autres dimensions:</p>
        <div className='flex flex-col self-center md:flex md:flex-row md:justify-center md:flex-wrap gap-7 lg:gap-14 mt-5 mb-5'>
          {otherDimensions.length ? (
            otherDimensions.map((data) => (
              <RecommendationCard
                key={data.id}
                dimension={data.dimension}
                score={data.score}
                score_type={data.score_type}
                recommendation={data.recommendation}
                id={data.id}
                handleDimensionSelection={handleDimensionSelection}
              />
            ))
          ) : (
            <div className='col-span-full'>
              <p className='font-bold text-xl md:text-2xl text-gray-600 mr-5 ml-5 md:mr-20 md:ml-20'>
                Malheureusement toutes vos dimensions sont faibles.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DimensionsChoicesScene;
