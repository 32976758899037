import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { device } from '../styles/BreakPoints';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import InputWithLabel from '../components/Inputs/InputSC';
import Button from '../components/Buttons/ButtonSC';

import BgVector from '../assets/img/background_vector_2.webp';
import BgVector2 from '../assets/img/background_vector_3.webp';
import OnePrevLogoVertical from '../assets/img/OnePrev_logo-vertical.webp';
import PasswordIcon from '../assets/svg/icon/password.svg';
import EmailIcon from '../assets/svg/icon/email.svg';
import DiscordIcon from '../assets/svg/icon/discord.svg';
import { useAppDispatch, useAppSelector } from '../app/hook';
import { login, reset } from '../features/auth/authSlice';
import Error from '../components/Alert/Error';
import { getUserData } from '../features/user/userSlice';

const Background = styled.div`
  background-image: url(${BgVector});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.md} {
    background-image: none;
  }
`;

const Box = styled.div`
  height: 850px;
  width: 1500px;
  display: flex;
`;

const LeftBox = styled.div`
  background-image: url(${BgVector2});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 65%;
  border-radius: 35px 0px 0 35px;
  display: flex;
  align-items: center;

  @media ${device.xl} {
    border-radius: 0 0 0 0;
    justify-content: center;
  }

  @media ${device.md} {
    display: none;
  }
`;

const RightBox = styled.div`
  background-color: white;
  height: 100%;
  width: 35%;
  border-radius: 0px 35px 35px 0px;

  @media ${device.xl} {
    border-radius: 0 0 0 0;
  }

  @media ${device.md} {
    width: 100%;
  }
`;

const LeftContent = styled.div`
  margin-left: 140px;
  width: 80%;

  @media ${device.xl} {
    margin-left: 0;
  }
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const FormHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 30px 0;
`;

const H1 = styled.h1`
  color: ${(props) => props.color};
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 40px;

  @media ${device.lg} {
    font-size: 40px;
  }

  @media ${device.md} {
    font-size: 30px;
  }
`;

interface IP400Props {
  color: string; // Déclarez le type de la propriété img
  size?: string;
}

const P400 = styled.p<IP400Props>`
  color: ${(props) => props.color};
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: ${(props) => props.size || '16px'};

  @media ${device.lg} {
    font-size: 12px;
  }

  @media ${device.lg} {
    line-break: normal;
  }
`;

interface IOnePrevLogoProps {
  img: string; // Déclarez le type de la propriété img
}

const OnePrevLogo = styled.img.attrs<IOnePrevLogoProps>((props) => ({
  src: props.img,
}))`
  height: 40px;

  @media ${device.md} {
    height: 40px;
  }
`;

const FormContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 25px 0px;
  width: 100%;
`;

const BottomLink = styled.div`
  display: flex;
  gap: 5px;
  margin-bottom: 5px;

  @media ${device.xs} {
    flex-direction: column;
    gap: 2px;
    justify-content: center;
    align-items: center;
  }
`;

const Login = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const dispatch = useAppDispatch();
  const { isAuthenticated, isSuccess, isError, message, token } =
    useAppSelector((state) => state.auth);
  const user = useAppSelector((state) => state.user);

  const navigate = useNavigate();
  const { state } = useLocation();

  async function handleConnectWithDiscord() {
    window.location.href = `${process.env.REACT_APP_BASE_URL_API}/discord/auth`;
  }

  const handleLogin = async () => {
    const userData = {
      email,
      password,
    };
    dispatch(login(userData));
  };

  useEffect(() => {
    if (isSuccess && isAuthenticated && token) {
      dispatch(getUserData(token));
    }
  }, [isSuccess, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && user.isSuccess && !user.isScreened)
      navigate('/screening');
    else if (isAuthenticated && user.isSuccess) navigate(state?.path || '/');
  }, [user.isSuccess, user.isError]);

  useEffect(() => {
    dispatch(reset({}));
  }, []);

  return (
    <Background>
      <Box>
        <LeftBox>
          <LeftContent>
            <H1 color='white'>Bienvenue sur One Prev !</H1>
            <P400 color='white'>
              Vous pouvez vous connecter avec votre compte existant.
            </P400>
          </LeftContent>
        </LeftBox>
        <RightBox>
          <RightContent>
            {isError && (
              <div className='w-9/12 flex flex-col justify-center mb-10'>
                <Error headerMessage={message} />
              </div>
            )}
            <FormHeader>
              <Link to='/'>
                <OnePrevLogo img={OnePrevLogoVertical} alt='logo_one_prev' />
              </Link>
              <H1 color='#424242'>Connexion</H1>
            </FormHeader>
            <FormContainer>
              <InputWithLabel
                type='email'
                placeholder='Email'
                logoSrc={EmailIcon}
                onChange={(e) => setEmail(e.target.value)}
              />
              <InputWithLabel
                type='password'
                placeholder='Mot de passe'
                logoSrc={PasswordIcon}
                onChange={(e) => setPassword(e.target.value)}
              />
              <ButtonContainer>
                <Button text='Se connecter' actionFunc={handleLogin} />
                <Button
                  text='Se connecter avec Discord'
                  bgColor='#5865F2'
                  hoverColor='#626fff'
                  activeColor='#4751bf'
                  iconSrc={DiscordIcon}
                  actionFunc={handleConnectWithDiscord}
                />
              </ButtonContainer>
            </FormContainer>
            <BottomLink>
              <P400 color='black'>Vous n'avez pas de compte ?</P400>
              <P400 color='black'>
                <Link
                  className='text-sky-800 hover:underline'
                  to='/inscription'
                >
                  Créer un compte
                </Link>
              </P400>
            </BottomLink>
            <BottomLink>
              <P400 color='black'>
                <Link className='text-sky-800 hover:underline' to='#'>
                  Mot de passe oublié ?
                </Link>
              </P400>
            </BottomLink>
          </RightContent>
        </RightBox>
      </Box>
    </Background>
  );
};

export default Login;
