import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

interface IInputProps {
  id: string | number;
  value: string;
  changeInputValue: (e: React.ChangeEvent<HTMLInputElement>) => any;
  handleValue?: (...args: any) => any;
  placeholder?: string;
  isError: boolean;
  errorMessage: string;
}

const Input: React.FC<IInputProps> = ({
  id,
  value,
  isError,
  errorMessage,
  changeInputValue,
}) => {
  return (
    <>
      {!isError ? (
        <input
          type='text'
          id={id as string}
          value={value}
          className='block w-full rounded-md border-0 py-6 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-xl sm:leading-6'
          placeholder='Entrez votre réponse ici...'
          onChange={(e) => changeInputValue(e)}
        />
      ) : (
        <div>
          <div className='relative rounded-md shadow-sm'>
            <input
              type='text'
              className='block w-full rounded-md border-0 py-6 px-4 pr-10 text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-xl sm:leading-6'
              placeholder='Entrez votre réponse ici...'
              value={value}
              onChange={(e) => changeInputValue(e)}
              aria-invalid='true'
            />
            <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
              <ExclamationCircleIcon
                className='h-5 w-5 text-red-500'
                aria-hidden='true'
              />
            </div>
          </div>
          <p className='mt-2 text-sm text-red-600' id='input-error'>
            {errorMessage}
          </p>
        </div>
      )}
    </>
  );
};

export default Input;
