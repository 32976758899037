import React, { useEffect, useState } from 'react';
import HealthProfile from '../components/HealthProfile';
import { formatDistance, subDays } from 'date-fns';
import { fr } from 'date-fns/locale';

import { axiosPrivateWithToken } from '../api/axios';
import { useAppSelector } from '../app/hook';
import Button from '../components/Buttons/Button';
import Success from '../components/Alert/Success';
import Error from '../components/Alert/Error';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
  const { token, isAuthenticated } = useAppSelector((state) => state.auth);
  const { id, isAdmin, isScreened } = useAppSelector((state) => state.user);
  const [lastScreeningDate, setLastScreeningDate] = useState(null);
  const [resetUserFetch, setResetUserFetch] = useState({
    statusCode: 0,
    message: '',
  });
  const navigation = useNavigate();

  const getLastScreeningDate = async () => {
    const axiosPrivate = axiosPrivateWithToken(token);
    try {
      const response = await axiosPrivate.get(
        'api/screening/last_screening_date'
      );
      setLastScreeningDate(response.data.last_screening_date);
    } catch (error) {
      console.error(error);
    }
  };

  const resetUser = async () => {
    const axiosPrivate = axiosPrivateWithToken(token);
    try {
      const response = await axiosPrivate.delete(`api/users/${id}/reset`);
      setResetUserFetch({
        statusCode: 200,
        message:
          'Votre profil a été réinitialisé, vous maintenant pouvez refaire un screening.',
      });
    } catch (error) {
      setResetUserFetch({
        statusCode: 400,
        message:
          'Une erreur a eu lieu lors de la réinitilisation de votre compte.',
      });
      console.error(error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) getLastScreeningDate();
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && !isScreened) {
      navigation('/screening');
    }
  }, [isScreened]);

  return (
    <div className='mt-8 ml-10 mr-10 md:mt-12 md:ml-16 md:mr-16'>
      {resetUserFetch.statusCode !== 0 && (
        <div className='ml-16 mr-16 mb-8'>
          {resetUserFetch.statusCode === 200 && (
            <Success headerMessage={resetUserFetch.message} />
          )}
          {resetUserFetch.statusCode === 400 && (
            <Error headerMessage={resetUserFetch.message} />
          )}
        </div>
      )}
      <div className='flex flex-row justify-between'>
        <h1 className='text-xl font-bold'>Mon profil de forme</h1>
        {isAdmin && (
          <Button actionFunc={resetUser} text='Réinitialiser le compte' />
        )}
      </div>
      {lastScreeningDate && (
        <div className='mt-2 text-sm text-gray-500'>
          Dernier screening effectué :{' '}
          {formatDistance(new Date(lastScreeningDate), new Date(), {
            addSuffix: true,
            locale: fr,
          })}
        </div>
      )}

      <div className='mt-8'>
        <HealthProfile />
      </div>
    </div>
  );
};

export default Profile;
