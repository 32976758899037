import React, { useEffect, useState } from 'react';
import Datepicker, { DatepickerType } from 'react-tailwindcss-datepicker';

const options = {
  todayBtn: false,
  clearBtnText: 'Effacer',
  todayBtnText: "Aujourd'hui",
  maxDate: new Date('2030-01-01'),
  minDate: new Date('1950-01-01'),
  language: 'fr',
  weekDays: ['L', 'M', 'M', 'J', 'V', 'S', 'D'],
  inputNameProp: 'date',
  inputIdProp: 'date',
  inputPlaceholderProp: 'Sélectionner une date',
};

interface IDatePickerProps {
  setInputDate: React.Dispatch<React.SetStateAction<string | null>>;
  dateValue: string | null;
  isError: boolean;
  errorMessage: string;
}

const DatePicker: React.FC<IDatePickerProps> = ({
  setInputDate,
  dateValue,
  isError,
  errorMessage,
}) => {
  const [value, setValue] = useState<{
    startDate: string | null;
    endDate: string | null;
  }>({
    startDate: dateValue,
    endDate: dateValue,
  });

  const handleValueChange = (newValue: any) => {
    setValue(newValue);
  };

  useEffect(() => {
    setInputDate(value.startDate);
  }, [value]);

  // Get last answer when user goes backwards
  useEffect(() => {
    if (value.startDate === null)
      setValue({ startDate: dateValue, endDate: dateValue });
  }, [dateValue]);

  return (
    <>
      {isError && <p className='text-red-600 my-2 text-sm'>{errorMessage}</p>}
      <Datepicker
        primaryColor={'cyan'}
        useRange={false}
        asSingle={true}
        value={value}
        onChange={handleValueChange}
        displayFormat={'DD/MM/YYYY'}
        i18n={'fr'}
      />
    </>
  );
};

export default DatePicker;
