import Cookies from 'js-cookie';
import axios from '../../api/axios';
import { IUserDataAuth } from '../../types/Users';

const register = async (userData: IUserDataAuth) => {
  const response = await axios.post('/api/register', userData);

  if (response.data) {
    localStorage.setItem('token', response.data.token);
  }

  return response.data.token;
};

const login = async (userData: IUserDataAuth) => {
  const response = await axios.post('/api/login', userData);

  if (response.data) {
    console.log(response.data.token);
    localStorage.setItem('token', response.data.token);
  }

  return response.data.token;
};

const logout = () => {
  localStorage.removeItem('token');
  Cookies.remove('service_token');
};

const authService = {
  register,
  login,
  logout,
};

export default authService;
