import React, { useEffect, useState } from 'react';
import Steps from './Steps';
import { motion, AnimatePresence } from 'framer-motion';
import IconButton from '../Buttons/IconButtons/IconButton';

import { ArrowUpIcon } from '@heroicons/react/20/solid';
import { ArrowDownIcon } from '@heroicons/react/20/solid';
import { DIRECTION } from '../../constants/Form';
import { IAnswers, IQuestion } from '../../types/Question';
import Question from './Question';

interface IFormAnimationProps {
  questions: IQuestion[];
  answers: IAnswers[];
  currentStep: number;
  totalSteps: number;
  forwardOrBackward: string | null;
  setForwardOrBackward: React.Dispatch<React.SetStateAction<string | null>>;
  handleNextQuestion: (newAnswer: IAnswers) => Promise<void>;
  handlePreviousQuestion: () => void;
}

const FormAnimation: React.FC<IFormAnimationProps> = ({
  questions,
  answers,
  currentStep,
  totalSteps,
  forwardOrBackward,
  setForwardOrBackward,
  handleNextQuestion,
  handlePreviousQuestion,
}) => {
  const [animationDirection, setAnimationDirection] = useState<string>(
    DIRECTION['FORWARD']
  );
  const [isAnimation, setIsAnimation] = useState(true);

  const [triggerForwardButton, setTriggerForwardButton] =
    useState<boolean>(false);

  const onAnimationComplete = () => {
    setIsAnimation(false);
  };

  const onAnimationStart = () => {
    setIsAnimation(true);
  };

  const handleNext = () => {
    if (!isAnimation && currentStep !== totalSteps - 1) {
      setAnimationDirection(DIRECTION['FORWARD']);
      setForwardOrBackward(null);
    }
  };

  const handlePrevious = () => {
    if (!isAnimation && currentStep !== 0) {
      setAnimationDirection(DIRECTION['BACKWARD']);
      setForwardOrBackward(null);
    }
  };

  // Need this function to check if user can go to next step, it will trigger a function in child component
  const handleForwardButton = () => {
    setTriggerForwardButton(true);
  };

  useEffect(() => {
    if (forwardOrBackward === DIRECTION['FORWARD']) {
      handleNext();
    } else if (forwardOrBackward === DIRECTION['BACKWARD']) {
      handlePrevious();
    }
  }, [forwardOrBackward]);

  return (
    <div className='h-screen w-screen overflow-y-hidden'>
      <Steps currentStep={currentStep} totalSteps={totalSteps} />
      <div className='absolute right-4 top-8 gap-2 flex'>
        <IconButton
          Icon={ArrowUpIcon}
          disabled={currentStep === 0 || false}
          actionFunc={handlePreviousQuestion}
        />
        <IconButton
          Icon={ArrowDownIcon}
          disabled={currentStep === totalSteps - 1 || false}
          actionFunc={handleForwardButton}
        />
      </div>
      <AnimatePresence mode='wait'>
        <motion.div
          key={currentStep}
          initial={
            animationDirection === DIRECTION['BACKWARD']
              ? { opacity: 0, y: -400 }
              : { opacity: 0, y: 400 }
          }
          animate={{ opacity: 1, y: 0 }}
          exit={
            animationDirection === DIRECTION['BACKWARD']
              ? { opacity: 0, y: 400 }
              : { opacity: 0, y: -400 }
          }
          transition={{ duration: 0.3 }}
          onAnimationComplete={onAnimationComplete}
          onAnimationStart={onAnimationStart}
        >
          <Question
            question={questions[currentStep]}
            currentQuestion={currentStep}
            totalQuestions={totalSteps}
            answers={answers}
            handleNextQuestion={handleNextQuestion}
            triggerForwardButton={triggerForwardButton}
            setTriggerForwardButton={setTriggerForwardButton}
          />
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default FormAnimation;
