import React, { useEffect, useState } from 'react';
import { axiosPrivateWithToken } from '../api/axios';
import { useAppSelector } from '../app/hook';
import { useNavigate } from 'react-router-dom';
import { IFollowUp } from '../types/FollowUp';
import FollowUpCard from '../components/FollowUp/FollowUpCard';

const FollowUps = () => {
  const { token, isAuthenticated } = useAppSelector((state) => state.auth);
  const { isScreened } = useAppSelector((state) => state.user);
  const [followUps, setFollowUps] = useState<IFollowUp[] | null>(null);
  const navigation = useNavigate();

  const getFollowUps = async () => {
    const axiosPrivate = axiosPrivateWithToken(token);
    try {
      const response = await axiosPrivate.get('api/user/follow_ups');
      setFollowUps(response.data.follow_ups);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) getFollowUps();
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && !isScreened) {
      navigation('/screening');
    }
  }, [isScreened]);

  return (
    <div className='mt-8 ml-10 mr-10 md:mt-12 md:ml-16 md:mr-16'>
      <div className='flex flex-row justify-between'>
        <h1 className='text-xl font-bold'>Mes follow ups</h1>
      </div>
      <div className='flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-8'>
        {followUps &&
          followUps.map((followUp, index) => (
            <FollowUpCard key={index} followUp={followUp} />
          ))}
      </div>
    </div>
  );
};

export default FollowUps;
