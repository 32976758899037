import React, { useEffect, useState } from 'react';
import RadarChart from '../components/RadarChart';
import { axiosPrivateWithToken } from '../api/axios';
import { useAppSelector } from '../app/hook';
import { IDimensionScore, IRadarChartScore } from '../types/Score';
import DimensionScoreCard from './Score/DimensionScoreCard';
import { motion, AnimatePresence } from 'framer-motion';

const colorByScore: any = {
  P1: 'text-amber-500',
  P2: 'text-red-600',
  P3: 'text-sky-600',
  P4: 'text-green-600',
};

const HealthProfile = () => {
  const { token, isAuthenticated } = useAppSelector((state) => state.auth);
  const { username } = useAppSelector((state) => state.user);

  const [radarScores, setRadarScores] = useState<IRadarChartScore[]>([]);
  const [dimensionScores, setDimensionScores] = useState<IDimensionScore[]>([]);

  function formatDataForRadar(data: IDimensionScore[]) {
    let formattedData: IRadarChartScore[] = [];
    data.map((scoreData: IDimensionScore) => {
      formattedData.push({
        x: scoreData.dimension,
        y: scoreData.score,
      });
    });
    return formattedData;
  }

  const getDimensionsScore = async () => {
    const axiosPrivate = axiosPrivateWithToken(token);
    try {
      const response = await axiosPrivate.get(`/api/score/dimensions`);

      if (response.data.dimensions_score) {
        const data = response.data.dimensions_score;
        setDimensionScores(data);
        const formattedData = formatDataForRadar(
          response.data.dimensions_score
        );

        setRadarScores(formattedData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  function percentConverter(
    score: number,
    scoreMin: number = -3,
    scoreMax: number = 2
  ) {
    return Math.round(((score - scoreMin) / (scoreMax - scoreMin)) * 100);
  }

  useEffect(() => {
    if (isAuthenticated) getDimensionsScore();
  }, [isAuthenticated]);

  const item = {
    hidden: { y: 90, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.6 },
    },
  };

  return (
    <div className='bg-gray-100 p-4 py-8 rounded-3xl md:p-7 md:rounded-[5rem]'>
      <div className='flex flex-col justify-center items-center w-full h-full gap-16 xl:flex-row'>
        <div className='flex flex-col sm:w-3/4  md:h-full md:w-1/3'>
          <RadarChart radarScores={radarScores} username={username} />
          <div className='flex flex-col gap-3 text-center justify-evenly p-2 xl:flex-row'>
            {dimensionScores.map((score, index) => (
              <div
                key={index}
                className={`font-bold text-sm ${
                  colorByScore[score.score_type]
                }`}
              >
                {score.dimension} : {percentConverter(score.score)}%
              </div>
            ))}
          </div>
        </div>
        <div className='flex flex-col gap-12 md:w-2/3 xl:gap-2 '>
          {dimensionScores &&
            dimensionScores.map((score, index) => (
              <motion.div
                variants={item}
                initial='hidden'
                animate='visible'
                key={score.id}
              >
                <DimensionScoreCard
                  key={score.id}
                  scoreLabel={score.score_type}
                  dimensionName={score.dimension}
                />
              </motion.div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default HealthProfile;
