import React, { useEffect, useState } from 'react';
import LargeButton from '../components/Buttons/LargeButton';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useAppSelector } from '../app/hook';
import { FORM_STATUS } from '../constants/Form';
import Form from '../components/Form/Form';

const STATUS = {
  ...FORM_STATUS,
};

const FORM_RESPONSE = {
  SUCCESS: 200,
  ALREADY_ANSWERED: 206,
  ERROR: 400,
  NOT_ALLOWED: 401,
};

const AlreadyAnswered: React.FC = () => {
  const navigate = useNavigate();

  const navigateToProfilePage = () => {
    navigate('/');
  };

  return (
    <div className='flex justify-center items-center h-screen w-screen flex-col'>
      <div className='flex justify-center flex-col w-3/5 gap-7 text-center items-center'>
        <h1 className='text-4xl font-bold my-6'>Vous avez déjà répondu</h1>
        <div>
          <p>
            Vous avez déjà répondu à ce suivi. Vous pouvez trouvez votre état de
            forme et vos progression sur votre profil.
          </p>
        </div>
        <div className='flex flex-col w-2/5'>
          <LargeButton
            text="Aller à l'accueil"
            actionFunc={navigateToProfilePage}
          />
        </div>
      </div>
    </div>
  );
};

const LoadingScene: React.FC = () => {
  return (
    <div className='flex justify-center items-center h-screen w-screen flex-col'>
      <div className='flex justify-center flex-col w-3/5 gap-7 text-center items-center'>
        <div className='animate-pulse w-9/12 h-9 mr-3 bg-gray-200 rounded-full' />
        <div className='w-full'>
          <div className='animate-pulse h-4 bg-gray-200 rounded-full dark:bg-gray-700 mb-4'></div>
          <div className='animate-pulse h-3.5 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5'></div>
          <div className='animate-pulse h-3.5 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5'></div>
          <div className='animate-pulse h-3.5 bg-gray-200 rounded-full dark:bg-gray-700'></div>
        </div>
        <div className='flex flex-col items-center w-2/5'>
          <div className='animate-pulse w-48 h-10 mr-3 bg-gray-200 rounded-full' />
        </div>
      </div>
    </div>
  );
};

const NotAllowed: React.FC = () => {
  const navigate = useNavigate();

  const navigateToProfilePage = () => {
    navigate('/');
  };

  return (
    <div className='flex justify-center items-center h-screen w-screen flex-col'>
      <div className='flex justify-center flex-col w-3/5 gap-7 text-center items-center'>
        <h1 className='text-4xl font-bold my-6'>
          Ce formulaire n'existe plus ou vous n'êtes pas autorisé à le
          consulter.
        </h1>

        <div className='flex flex-col w-2/5'>
          <LargeButton
            text="Aller à l'accueil"
            actionFunc={navigateToProfilePage}
          />
        </div>
      </div>
    </div>
  );
};

const EndScene: React.FC = () => {
  const navigate = useNavigate();

  const navigateToProfilePage = () => {
    navigate('/profil');
  };

  return (
    <div className='flex justify-center items-center h-screen w-screen flex-col'>
      <div className='flex justify-center flex-col w-3/5 gap-7 text-center items-center'>
        <h1 className='text-4xl font-bold my-6'>
          Merci d'avoir rempli le formulaire !
        </h1>
        <div>
          <p>
            Vous pouvez trouvez votre état de forme sur chacune des dimensions
            en allant consulter votre page profil.
          </p>
        </div>
        <div className='flex flex-col w-2/5'>
          <LargeButton text='Mon profil' actionFunc={navigateToProfilePage} />
        </div>
      </div>
    </div>
  );
};

const FollowUpForm = () => {
  const [followUpStatus, setFollowUpStatus] = useState<number>(
    STATUS['FETCHING_FORM']
  );
  const [formResponseStatus, setFormResponseStatus] = useState(0);
  const { isScreened } = useAppSelector((state) => state.user);
  let { id } = useParams();

  const formEndPoint = `api/follow_up/form/${id}`;
  const answerEndPoint = '/api/follow_up/answer';

  useEffect(() => {
    if (followUpStatus === STATUS['READY']) {
      setFollowUpStatus(STATUS['IN_PROGRESS']);
    }
  }, [followUpStatus]);

  console.log(formResponseStatus);

  if (formResponseStatus === FORM_RESPONSE['ALREADY_ANSWERED']) {
    return <AlreadyAnswered />;
  }

  if (
    formResponseStatus === FORM_RESPONSE['NOT_ALLOWED'] ||
    formResponseStatus === FORM_RESPONSE['ERROR']
  ) {
    return <NotAllowed />;
  }

  return (
    <div className='h-screen w-screen overflow-y-hidden'>
      {followUpStatus === STATUS['FETCHING_FORM'] && <LoadingScene />}
      <Form
        formEndpoint={formEndPoint}
        answerEndpoint={answerEndPoint}
        formStatus={followUpStatus}
        setFormStatus={setFollowUpStatus}
        setFormResponseStatus={setFormResponseStatus}
      />
      {followUpStatus === STATUS['FINISH'] && <EndScene />}
    </div>
  );
};

export default FollowUpForm;
