import React from 'react';

import { CheckIcon } from '@heroicons/react/20/solid';

interface ISuccessProps {
  headerMessage: string;
}

const Success: React.FC<ISuccessProps> = ({ headerMessage }) => {
  return (
    <div className='rounded-md bg-emerald-50 p-4'>
      <div className='flex'>
        <div className='flex-shrink-0'>
          <CheckIcon className='h-5 w-5 text-emerald-400' aria-hidden='true' />
        </div>
        <div className='ml-3 break-words'>
          <h3 className='text-sm font-medium text-emerald-800 whitespace-pre-line'>
            {headerMessage}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Success;
