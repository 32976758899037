import React from 'react';

interface IStepsProps {
  currentStep: number;
  totalSteps: number;
}

const Steps: React.FC<IStepsProps> = ({ currentStep, totalSteps }) => {
  return (
    <div className='w-screen absolute top-0'>
      <div className='' aria-hidden='true'>
        <div className='overflow-hidden bg-gray-200'>
          <div
            className='h-3 bg-teal-500'
            style={{
              width: `${(currentStep / totalSteps) * 100}%`,
              transition: 'ease 2s',
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Steps;
