import React, { useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { IChoice, IQuestion } from '../../types/Question';

interface IRadiosProps {
  question: IQuestion;
  choicesList: IChoice[] | [];
  handleNextQuestion: (...args: any) => any;
  selectedMailingLists: IChoice | null;
  setSelectedMailingLists: React.Dispatch<React.SetStateAction<IChoice | null>>;
  isError: boolean;
  errorMessage: string;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const Radios: React.FC<IRadiosProps> = ({
  question,
  choicesList,
  handleNextQuestion,
  selectedMailingLists,
  setSelectedMailingLists,
  isError,
  errorMessage,
}) => {
  return (
    <RadioGroup
      value={selectedMailingLists}
      onChange={(value) => {
        setSelectedMailingLists(value);
        handleNextQuestion({
          question_id: question.id,
          question_text: question.question,
          dimension: question.dimension,
          category_id: question.category_id,
          answer_value: value?.choice_value,
          answer_text: value?.choice_text,
        });
      }}
    >
      <div className='mt-4 flex flex-col gap-3'>
        {isError && (
          <span className='text-red-600 mt-2 text-sm'>{errorMessage}</span>
        )}
        {choicesList.map((choicesList, index) => (
          <RadioGroup.Option
            key={index}
            value={choicesList}
            className={({ active }) =>
              classNames(
                active
                  ? 'border-sky-600 ring-2 ring-sky-600'
                  : 'border-gray-300',
                'relative flex cursor-pointer rounded-lg border bg-white p-3 shadow-sm focus:outline-none hover:bg-slate-100'
              )
            }
          >
            {({ checked, active }) => (
              <>
                <span className='flex flex-1'>
                  <span className='flex flex-row items-center gap-5'>
                    <RadioGroup.Label
                      as='span'
                      className='block text-sm  text-gray-500'
                    >
                      {(index + 1 + 9).toString(36).toUpperCase()}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as='span'
                      className='mt-1 flex items-center font-medium text-base text-gray-900'
                    >
                      {choicesList.choice_text}
                    </RadioGroup.Description>
                  </span>
                </span>
                <CheckCircleIcon
                  className={classNames(
                    !checked ? 'invisible' : '',
                    'h-5 w-5 text-sky-600'
                  )}
                  aria-hidden='true'
                />
                <span
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-sky-600' : 'border-transparent',
                    'pointer-events-none absolute -inset-px rounded-lg'
                  )}
                  aria-hidden='true'
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default Radios;
