import React from 'react';
import LargeButton from '../Buttons/LargeButton';
import { UserCircleIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';

interface ISelectMessageServicesProps {}

const SelectMessageServices: React.FC<ISelectMessageServicesProps> = () => {
  const navigate = useNavigate();

  const goToProfil = () => {
    navigate('/profil');
  };
  return (
    <div className='flex flex-col m-5 gap-10 justify-center sm:m-10 md:m-16 lg:gap-12'>
      <div className='flex flex-col gap-6 text-center md:justify-between md:flex-row md:gap-6 md:text-start'>
        <p className='text-2xl md:text-3xl lg:text-4xl font-bold'>
          Une dernière chose !
        </p>
        <LargeButton
          text='Mon profil'
          RightIcon={UserCircleIcon}
          actionFunc={goToProfil}
        />
      </div>
      <div className='flex flex-col gap-5'>
        <p className='whitespace-pre-line font-semibold'>
          {
            'Bravo ! Vous avez configurez avec succès vos suivis personnalisés !'
          }
        </p>
        <p className='whitespace-pre-line'>
          {
            'Nous utilisons Discord pour envoyer des rappels à nos utilisateurs concernant les suivis.\nNous vous invitons à joindre notre serveur Discord si vous souhaitez bénéficier du meilleur accompagnement possible.\n(Vous pouvez le rejoindre ultérieurement si vous le souhaitez)'
          }
        </p>
        <p className='font-bold'>
          Une fois que vous aurez rejoint notre serveur, vous pouvez retourner
          sur votre profil pour consulter vos suivis en cours
        </p>
      </div>
      <div className='flex flex-col gap-12'>
        <p className='text-xl md:text-2xl font-bold '>
          Rejoignez notre serveur Discord
        </p>
        <div className='flex justify-center'>
          <iframe
            src='https://discord.com/widget?id=1175065573311381605&theme=dark'
            width='350'
            height='500'
            allowTransparency={true}
            sandbox='allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts'
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default SelectMessageServices;
