import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { IRadarChartScore } from '../types/Score';

interface IRadarChartProps {
  radarScores: IRadarChartScore[];
  username: string;
}

const textColorByScore: any = {
  P1: '#f59e0b',
  P2: '#dc2626',
  P3: '#0284c7',
  P4: '#16a34a',
};

const RadarChart: React.FC<IRadarChartProps> = ({ radarScores, username }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const mettreAJourTailleFenetre = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', mettreAJourTailleFenetre);

    // Nettoyage du listener lors du démontage du composant
    return () => {
      window.removeEventListener('resize', mettreAJourTailleFenetre);
    };
  }, []); // Le tableau vide [] garantit que cet effet ne s'exécute qu'une seule fois lors du montage

  const chartConfig: any = {
    chart: {
      id: 'basic-bar',
      width: 200,
      toolbar: {
        show: false,
      },
      offsetY: 50,
    },
    title: {
      text: username,
      align: 'center',
      style: {
        fontSize: '20px',
        color: '#595959',
      },
    },
    markers: {
      size: 6,
    },
    xaxis: {
      labels: {
        show: true,
        trim: true,
        style: {
          fontSize: '14px', // Ajustez la taille selon vos besoins
          fontWeight: '800',
          colors: ['#000000', '#000000', '#000000'],
        },
      },
    },
    yaxis: {
      show: false,
      min: -4,
      max: 3,
      labels: {
        formatter: function (value: number) {
          if (value <= 0 && value > -1) return 'P1';
          else if (value <= -1) return 'P2';
          else if (value === 1) return 'P3';
          else if (value > 2) return 'P4';
          else return 'P4';
        },
      },
    },
  };
  const [series, setSeries] = useState<any>([
    {
      name: 'Score',
      data: [],
    },
  ]);

  useEffect(() => {
    if (radarScores) {
      setSeries([
        {
          name: 'Score',
          data: radarScores,
        },
      ]);
    }
  }, [radarScores]);

  return (
    <div className=''>
      <Chart
        options={chartConfig}
        series={series}
        height={windowWidth > 640 ? 560 : 350}
        type='radar'
      />
    </div>
  );
};

export default RadarChart;
