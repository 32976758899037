import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { device } from '../styles/BreakPoints';

import InputWithLabel from '../components/Inputs/InputSC';
import Button from '../components/Buttons/ButtonSC';

import BgVector from '../assets/img/background_vector_2.webp';
import BgVector2 from '../assets/img/background_vector_3.webp';
import OnePrevLogoVertical from '../assets/img/OnePrev_logo-vertical.webp';
import PasswordIcon from '../assets/svg/icon/password.svg';
import UserIcon from '../assets/svg/icon/user.svg';
import EmailIcon from '../assets/svg/icon/email.svg';
import DiscordIcon from '../assets/svg/icon/discord.svg';

import { Link, useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import Error from '../components/Alert/Error';
import { useAppDispatch, useAppSelector } from '../app/hook';
import { register, reset, setError } from '../features/auth/authSlice';

const Background = styled.div`
  background-image: url(${BgVector});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.md} {
    background-image: none;
  }
`;

const Box = styled.div`
  height: 850px;
  width: 1500px;
  display: flex;
`;

const LeftBox = styled.div`
  background-image: url(${BgVector2});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 65%;
  border-radius: 35px 0px 0 35px;
  display: flex;
  align-items: center;

  @media ${device.xl} {
    border-radius: 0 0 0 0;
    justify-content: center;
  }

  @media ${device.md} {
    display: none;
  }
`;

const RightBox = styled.div`
  background-color: white;
  height: 100%;
  width: 35%;
  border-radius: 0px 35px 35px 0px;

  @media ${device.xl} {
    border-radius: 0 0 0 0;
  }

  @media ${device.md} {
    width: 100%;
  }
`;

const LeftContent = styled.div`
  margin-left: 140px;
  width: 80%;

  @media ${device.xl} {
    margin-left: 0;
  }
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const FormHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 30px 0;
`;

const H1 = styled.h1`
  color: ${(props) => props.color};
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 40px;

  @media ${device.lg} {
    font-size: 40px;
  }

  @media ${device.md} {
    font-size: 30px;
  }
`;

interface IP400Props {
  color: string; // Déclarez le type de la propriété img
  size?: string;
}

const P400 = styled.p<IP400Props>`
  color: ${(props) => props.color};
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: ${(props) => props.size || '16px'};

  @media ${device.lg} {
    font-size: 12px;
  }

  @media ${device.lg} {
    line-break: normal;
  }
`;

interface IOnePrevLogoProps {
  img: string; // Déclarez le type de la propriété img
}

const OnePrevLogo = styled.img.attrs<IOnePrevLogoProps>((props) => ({
  src: props.img,
}))`
  height: 40px;

  @media ${device.md} {
    height: 40px;
  }
`;

const FormContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 25px 0px;
  width: 100%;
`;

const BottomLink = styled.div`
  display: flex;
  gap: 5px;

  @media ${device.xs} {
    flex-direction: column;
    gap: 0px;
    justify-content: center;
    align-items: center;
  }
`;

const Register = () => {
  const [username, setUsername] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const dispatch = useAppDispatch();
  const { isAuthenticated, isSuccess, isError, message } = useAppSelector(
    (state) => state.auth
  );

  const navigate = useNavigate();

  const handleRegistration = async () => {
    const userData = {
      username,
      email,
      password,
    };

    if (password !== confirmPassword) {
      dispatch(setError('Les mots de passe ne correspondent pas.'));
      return;
    }

    dispatch(reset({}));
    dispatch(register(userData));
  };

  async function handleConnectWithDiscord() {
    window.location.href = `${process.env.REACT_APP_BASE_URL_API}/discord/auth`;
  }

  useEffect(() => {
    if (isSuccess && isAuthenticated) {
      navigate('/screening');
    }
  }, [isSuccess, isAuthenticated]);

  useEffect(() => {
    if (isError) {
      console.log('error');
    }
  }, [isError]);

  useEffect(() => {
    dispatch(reset({}));
  }, []);

  return (
    <Background>
      <Box>
        <LeftBox>
          <LeftContent>
            <H1 color='white'>Welcome to One Prev !</H1>
            <P400 color='white'>You can sign up to access to One Prev.</P400>
          </LeftContent>
        </LeftBox>
        <RightBox>
          <RightContent>
            {isError && (
              <div className='w-9/12 flex flex-col justify-center mb-10'>
                <Error headerMessage={message} />
              </div>
            )}
            <FormHeader>
              <Link to='/'>
                <OnePrevLogo img={OnePrevLogoVertical} alt='logo_one_prev' />
              </Link>
              <H1 color='#424242'>Inscription</H1>
            </FormHeader>
            <FormContainer>
              <InputWithLabel
                type='text'
                placeholder='Pseudo'
                logoSrc={UserIcon}
                onChange={(e) => setUsername(e.target.value)}
              />
              <InputWithLabel
                type='email'
                placeholder='Email'
                logoSrc={EmailIcon}
                onChange={(e) => setEmail(e.target.value)}
              />
              <InputWithLabel
                type='password'
                placeholder='Mot de passe'
                logoSrc={PasswordIcon}
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputWithLabel
                type='password'
                placeholder='Confirmer votre mot de passe'
                logoSrc={PasswordIcon}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <ButtonContainer>
                <Button text="S'inscrire" actionFunc={handleRegistration} />
                <Button
                  text="S'inscrire avec Discord"
                  bgColor='#5865F2'
                  hoverColor='#626fff'
                  activeColor='#4751bf'
                  iconSrc={DiscordIcon}
                  actionFunc={handleConnectWithDiscord}
                />
              </ButtonContainer>
            </FormContainer>
            <BottomLink>
              <P400 color='black'>Vous avez déjà un compte ?</P400>
              <P400 color='black'>
                <Link className='text-sky-800 hover:underline' to='/connexion'>
                  Se connecter
                </Link>
              </P400>
            </BottomLink>
          </RightContent>
        </RightBox>
      </Box>
    </Background>
  );
};

export default Register;
