import React from 'react';

import { XCircleIcon } from '@heroicons/react/20/solid';

interface IErrorProps {
  headerMessage: string;
  errorList?: string[];
}

const Error: React.FC<IErrorProps> = ({ headerMessage, errorList }) => {
  return (
    <div className='rounded-md bg-red-50 p-4'>
      <div className='flex'>
        <div className='flex-shrink-0 self-center'>
          <XCircleIcon className='h-5 w-5 text-red-400' aria-hidden='true' />
        </div>
        <div className='ml-3 break-words'>
          <h3 className='text-sm font-medium text-red-800 whitespace-pre-line'>
            {headerMessage}
          </h3>
          {/* {errorList && (
            <div className='mt-2 text-sm text-red-700'>
              <ul role='list' className='list-disc space-y-1 pl-5'>
                <li>Your password must be at least 8 characters</li>
                <li>
                  Your password must include at least one pro wrestling
                  finishing move
                </li>
                <li>
                  Your password must include at least one pro wrestling
                  finishing move
                </li>
                <li>
                  Your password must include at least one pro wrestling
                  finishing move
                </li>
              </ul>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default Error;
