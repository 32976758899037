import React from 'react';
import { ReactComponent as NutritionIcon } from '../../assets/svg/icon/apple.svg';
import { ReactComponent as PhysicalActivityIcon } from '../../assets/svg/icon/walk.svg';
import { ReactComponent as AddictionIcon } from '../../assets/svg/icon/wine.svg';
import { motion, AnimatePresence } from 'framer-motion';

interface IDimensionScoreCardProps {
  scoreLabel: string;
  dimensionName: string;
}

const textColorByScore: any = {
  P1: 'text-amber-500',
  P2: 'text-red-600',
  P3: 'text-sky-600',
  P4: 'text-green-600',
};

const bgColorByScore: any = {
  P1: 'bg-amber-500',
  P2: 'bg-red-600',
  P3: 'bg-sky-600',
  P4: 'bg-green-600',
};

const descriptionByScore: any = {
  P1: [
    'Déviation - Facile à corriger :',
    'Il y a des catégories où des améliorations sont nécessaires pour atteindre un score optimal sur cette dimension. Concentrez-vous sur ces aspects pour progresser.',
  ],
  P2: [
    'Déviation - Doit être corrigé : ',
    'Certains aspects de votre mode de vie nécessitent une attention particulière et des corrections sont nécessaires pour maintenir une bonne santé.',
  ],
  P3: [
    'À progresser  :',
    'Vos habitudes sont globalement bonnes. Quelques ajustements mineurs peuvent être faits pour améliorer encore plus.',
  ],
  P4: [
    'À ne pas régresser :',
    'Félicitations, vous maintenez de bonnes habitudes sur cette dimension. Continuez ainsi pour préserver votre état de forme actuel.',
  ],
  'Out of range': ['', ''],
};

const DimensionScoreCard: React.FC<IDimensionScoreCardProps> = ({
  scoreLabel,
  dimensionName,
}) => {
  const getIconByDimensionName = () => {
    switch (dimensionName) {
      case 'Nutrition':
        return (
          <NutritionIcon className='h-[4rem] w-[4rem] p-2 md:h-[6rem] md:w-[6rem] md:p-3 bg-gray-300 rounded-3xl' />
        );
      case 'Activité physique':
        return (
          <PhysicalActivityIcon className='h-[4rem] w-[4rem] p-2 md:h-[6rem] md:w-[6rem] md:p-3 bg-gray-300 rounded-3xl' />
        );
      case 'Addiction':
        return (
          <AddictionIcon className='h-[4rem] w-[4rem] p-2 md:h-[6rem] md:w-[6rem] md:p-3 bg-gray-300 rounded-3xl' />
        );
      default:
        return null;
    }
  };

  console.log(scoreLabel);

  return (
    <div className='flex flex-col gap-3 items-center m-1 md:m-4 md:gap-7 md:flex-row '>
      <div className='flex gap-5 justify-center items-center'>
        <h1
          className={`text-sm p-3.5 md:text-base md:p-5 font-bold rounded-full ${bgColorByScore[scoreLabel]} text-white`}
        >
          {scoreLabel}
        </h1>
        <div className=''>{getIconByDimensionName()}</div>
      </div>
      <div className='flex flex-col justify-between text-center md:text-start'>
        <h1
          className={`text-base md:text-lg font-bold ${textColorByScore[scoreLabel]}`}
        >
          {dimensionName}
        </h1>
        <div className='flex flex-col sm:w-[30rem] md:w-5/6 xl:w-3/4 gap-2'>
          <h5 className='text-base md:text-lg font-bold text-black'>
            {descriptionByScore[scoreLabel][0]}
          </h5>
          <p className='text-xs md:text-sm text-black'>
            {descriptionByScore[scoreLabel][1]}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DimensionScoreCard;
