import React, { useEffect, useState } from 'react';

import Input from '../Inputs/Input';
import Radios from '../Radios/Radios';
import DatePicker from '../DatePicker';
import { IAnswers, IChoice, IQuestion } from '../../types/Question';
import LargeButton from '../Buttons/LargeButton';
import Info from '../Alert/Info';
import { BG_COLOR_VARIANTS } from '../../constants/TailwindColors';
import { ReactComponent as TagIcon } from '../../assets/svg/icon/tag.svg';

interface IIQuestionProps {
  currentQuestion: number;
  totalQuestions: number;
  question: IQuestion;
  answers: IAnswers[];
  handleNextQuestion: (newAnswer: IAnswers) => void;
  triggerForwardButton: boolean;
  setTriggerForwardButton: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ILabelProps {
  text: string;
  index: number;
}

const Label: React.FC<ILabelProps> = ({ index, text }) => {
  return (
    <label className='flex md:text-2xl font-small leading-6 text-gray-900 gap-3'>
      <p className='text-teal-600 font-semibold'>{index + 1}.</p>
      {text}
    </label>
  );
};

const Question: React.FC<IIQuestionProps> = ({
  currentQuestion,
  totalQuestions,
  question,
  answers,
  handleNextQuestion,
  triggerForwardButton,
  setTriggerForwardButton,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedMailingLists, setSelectedMailingLists] =
    useState<IChoice | null>(null);
  const [inputDate, setInputDate] = useState<string | null>(null);

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const changeInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const checkAnswer = () => {
    if (question.type === 'input' && inputValue === '') {
      setIsError(true);
      setErrorMessage('Veuillez entrer une réponse.');
      return;
    }
    if (question.type === 'radio' && selectedMailingLists === null) {
      setIsError(true);
      setErrorMessage('Veuillez sélectionner une réponse.');
      return;
    }
    if (question.type === 'date' && inputDate === null) {
      setIsError(true);
      setErrorMessage('Veuillez sélectionner une date.');
      return;
    }
    handleNextQuestion({
      question_id: question.id,
      question_text: question.question,
      dimension: question.dimension,
      category_id: question.category_id,
      answer_text:
        question.type === 'input'
          ? (inputValue as string)
          : question.type === 'radio'
          ? selectedMailingLists?.choice_text
          : (inputDate as string),
      answer_value:
        question.type === 'input'
          ? inputValue
          : question.type === 'radio'
          ? selectedMailingLists?.choice_value
          : inputDate,
    });
  };

  useEffect(() => {
    if (isError) {
      setIsError(false);
      setErrorMessage('');
    }
  }, [inputDate, selectedMailingLists]);

  // Get last answer when user goes backwards
  useEffect(() => {
    if (
      answers[currentQuestion] !== undefined &&
      Object.keys(answers[currentQuestion]).length !== 0
    ) {
      if (question.type === 'input' && inputValue === '') {
        setInputValue(answers[currentQuestion].answer_value as string);
      } else if (question.type === 'date' && inputDate === null) {
        setInputDate(answers[currentQuestion].answer_value as string);
      } else if (question.type === 'radio' && selectedMailingLists === null) {
        const currentAnswer = question.choices?.find(
          (choice) =>
            choice.choice_value === answers[currentQuestion].answer_value
        );
        setSelectedMailingLists(currentAnswer as IChoice);
      }
    }
  }, [answers]);

  useEffect(() => {
    if (triggerForwardButton) {
      setTriggerForwardButton(false);
      checkAnswer();
    }
  }, [triggerForwardButton]);

  return (
    <div className='flex justify-center items-center h-screen w-screen flex-col gap-8'>
      {question.help && (
        <div className='w-4/5 md:w-2/5'>
          <Info headerMessage={question.help} />
        </div>
      )}

      <div className='flex flex-col w-4/5 md:w-2/5 gap-4 md:gap-7'>
        {question?.name && (
          <div className='flex items-center'>
            <TagIcon className='h-[2.5rem] w-[2.5rem] md:h-[4rem] md:w-[4rem]' />
            <p className='font-bold md:text-4xl text-sky-700'>
              {question.name}
            </p>
          </div>
        )}
        <Label text={question.question} index={currentQuestion} />
        {question?.target && question?.frequency && (
          <div className='flex items-center gap-3'>
            <p className='font-bold text-xs md:text-sm bg-emerald-500 text-white p-3 rounded-xl'>
              Objectif : {question.target}
            </p>
            <p className='font-bold text-xs md:text-sm bg-purple-600 text-white p-3 rounded-xl'>
              Depuis : 3 jours
            </p>
          </div>
        )}
        <div className='mt-2'>
          {question.type === 'input' && (
            <Input
              id={question.id}
              value={inputValue}
              changeInputValue={changeInputValue}
              errorMessage={errorMessage}
              isError={isError}
            />
          )}
          {question.type === 'radio' && (
            <Radios
              question={question}
              setSelectedMailingLists={setSelectedMailingLists}
              selectedMailingLists={selectedMailingLists}
              choicesList={question?.choices || []}
              handleNextQuestion={handleNextQuestion}
              isError={isError}
              errorMessage={errorMessage}
              key={currentQuestion}
            />
          )}
          {question.type === 'date' && (
            <DatePicker
              setInputDate={setInputDate}
              dateValue={inputDate}
              isError={isError}
              errorMessage={errorMessage}
            />
          )}
        </div>
        <LargeButton
          text={
            currentQuestion < totalQuestions - 1 || question.is_conditional
              ? 'Suivant'
              : 'Envoyer les réponses'
          }
          color={
            isError
              ? BG_COLOR_VARIANTS['red']['default']
              : BG_COLOR_VARIANTS['sky']['default']
          }
          actionFunc={() => checkAnswer()}
        />
      </div>
    </div>
  );
};

export default Question;
