import React, { useEffect, useState } from 'react';
import { IAnswers, IQuestion } from '../../types/Question';
import LargeButton from '../Buttons/LargeButton';
import { Link, useNavigate } from 'react-router-dom';

import { axiosPrivateWithToken } from '../../api/axios';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { userScreened } from '../../features/user/userSlice';
import { DIRECTION, FORM_STATUS } from '../../constants/Form';
import FormAnimation from './FormAnimation';

interface IFormProps {
  formEndpoint: string | null;
  answerEndpoint: string;
  formStatus: number;
  setFormStatus: React.Dispatch<React.SetStateAction<number>>;
  setFormResponseStatus: React.Dispatch<React.SetStateAction<number>>;
}

const Form: React.FC<IFormProps> = ({
  formEndpoint,
  answerEndpoint,
  formStatus,
  setFormStatus,
  setFormResponseStatus,
}) => {
  const { token, isAuthenticated } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const [formId, setFormId] = useState(null);

  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [answers, setAnswers] = useState<IAnswers[]>([]);

  const [forwardOrBackward, setForwardOrBackward] = useState<string | null>(
    null
  );

  const fetchForm = async () => {
    if (formEndpoint === null) return;

    const axiosPrivate = axiosPrivateWithToken(token);
    try {
      const response = await axiosPrivate.get(formEndpoint);

      setFormResponseStatus(response.status);

      console.log(response);

      if (response.status === 200) {
        setFormId(response.data.form_id);
        setQuestions(response.data.form_questions);
        setTotalQuestions(response.data.form_questions.length);
        setAnswers(Array(response.data.form_questions.length).fill({}));
        setFormStatus(FORM_STATUS['READY']);
      } else {
        return;
      }
    } catch (error: any) {
      setFormResponseStatus(error.response.status);
      console.error(error.response.status);
    }
  };

  const sendAnswers = async () => {
    const axiosPrivate = axiosPrivateWithToken(token);
    console.log(answers);

    try {
      const response = await axiosPrivate.post(answerEndpoint, {
        form_id: formId,
        answers: answers,
      });

      setFormStatus(FORM_STATUS['FINISH']);
      dispatch(userScreened());
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * This function check if answer of current question is valid scenario
   * for conditional questions
   *
   * @param { string | number | boolean | Date | [] | null | undefined} answer_value The value of the answer
   * @returns {{answer_value: string | number | boolean, questions :IQuestion[]} | undefined}
   * answer_value and conditional questions list if scenario is valid, otherwise undefined
   */
  const getValidScenario = (
    answer_value: string | number | boolean | Date | [] | null | undefined
  ) => {
    return questions[currentQuestion]['conditional_scenarios'].find(
      (scenario) => scenario.answer_value === answer_value
    );
  };

  /**
   * This function handle conditional question. It's check if the scenario is valid and fill questions array and answer array
   *
   * @param {any} currentQuestionAnswer The answer of current question
   * @param {IAnswers[]} updatedAnswers
   * @param {IQuestion[]} updatedQuestions
   * @returns {}
   */
  const handleConditionalQuestions = (
    currentQuestionAnswer: any,
    updatedAnswers: IAnswers[],
    updatedQuestions: IQuestion[]
  ) => {
    // const currentQuestionAnswer = answers[currentQuestion].answer_value;

    if (questions[currentQuestion]['is_conditional']) {
      const validScenario = getValidScenario(currentQuestionAnswer);

      if (validScenario) {
        // setCanUpdateConditionalQuestions(true);

        // setCanUpdateConditionalQuestions(true);
        updatedQuestions.splice(
          currentQuestion + 1,
          0,
          ...validScenario.questions
        );

        updatedAnswers.splice(
          currentQuestion + 1,
          0,
          ...Array(validScenario.questions.length).fill({})
        );

        console.log(updatedQuestions);
        console.log(updatedAnswers);
      }
    }
    return { updatedAnswers, updatedQuestions };
  };

  // enlever les réponses conditionnelles ?
  const handleLastAnswer = (
    currentQuestionAnswer:
      | string
      | number
      | boolean
      | Date
      | []
      | null
      | undefined,
    updatedAnswers: IAnswers[],
    updatedQuestions: IQuestion[]
  ) => {
    // if the current question has conditional questions
    if (questions[currentQuestion]['is_conditional']) {
      const validScenario = getValidScenario(currentQuestionAnswer);

      if (validScenario) {
        const filteredAnswers = updatedAnswers.filter(
          (answerItem) =>
            !validScenario['questions'].some(
              (conditionalQuestion) =>
                conditionalQuestion.id === answerItem.question_id
            )
        );

        const filteredQuestions = updatedQuestions.filter(
          (questionItem) =>
            !validScenario['questions'].some(
              (conditionalQuestion) =>
                conditionalQuestion.id === questionItem.id
            )
        );

        while (filteredAnswers.length !== filteredQuestions.length) {
          const indexOfEmpty = filteredAnswers.findIndex(
            (item) => Object.keys(item).length === 0
          );

          if (indexOfEmpty !== -1) {
            // Utilisons splice pour le retirer
            console.log('retirer');
            filteredAnswers.splice(indexOfEmpty, 1);
          } else {
            console.log('BREAK');
            break;
          }
        }

        updatedAnswers = filteredAnswers;
        updatedQuestions = filteredQuestions;
      }
    }
    return { updatedAnswers, updatedQuestions };
  };

  const handleNextQuestion = async (newAnswer: IAnswers) => {
    let currentTotalQuestions = totalQuestions;
    if (
      answers[currentQuestion] !== undefined &&
      answers[currentQuestion].answer_value !== newAnswer.answer_value
    ) {
      let updatedAnswers = [...answers];
      let updatedQuestions = [...questions];

      let updatedData = handleLastAnswer(
        newAnswer.answer_value,
        updatedAnswers,
        updatedQuestions
      ); // handle the last answer of the form

      updatedData.updatedAnswers[currentQuestion] = newAnswer;
      updatedData = handleConditionalQuestions(
        newAnswer.answer_value,
        updatedData.updatedAnswers,
        updatedData.updatedQuestions
      );

      setAnswers(updatedData.updatedAnswers);
      setQuestions(updatedData.updatedQuestions);
      setTotalQuestions(updatedData.updatedQuestions.length);
      currentTotalQuestions = updatedData.updatedQuestions.length;
    }
    if (currentQuestion < currentTotalQuestions - 1) {
      setForwardOrBackward(DIRECTION['FORWARD']);
      setTimeout(() => {
        setCurrentQuestion(currentQuestion + 1);
      }, 5);
    } else {
      setFormStatus(FORM_STATUS['SENDING_ANSWER']);
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const handlePreviousQuestion = () => {
    setForwardOrBackward(DIRECTION['BACKWARD']);
    setTimeout(() => {
      setCurrentQuestion(currentQuestion - 1);
    }, 5);
  };

  useEffect(() => {
    if (
      formEndpoint !== null &&
      formStatus === FORM_STATUS['FETCHING_FORM'] &&
      questions.length === 0 &&
      isAuthenticated
    ) {
      fetchForm();
    }
  }, [isAuthenticated, formEndpoint]);

  // send data to back-end after answers's setted
  useEffect(() => {
    if (formStatus === FORM_STATUS['SENDING_ANSWER']) {
      sendAnswers();
    }
  }, [answers]);

  return (
    <>
      {formStatus === FORM_STATUS['IN_PROGRESS'] && (
        <div className='h-screen w-screen overflow-y-hidden'>
          <FormAnimation
            questions={questions}
            answers={answers}
            currentStep={currentQuestion}
            totalSteps={totalQuestions}
            forwardOrBackward={forwardOrBackward}
            setForwardOrBackward={setForwardOrBackward}
            handleNextQuestion={handleNextQuestion}
            handlePreviousQuestion={handlePreviousQuestion}
          />
        </div>
      )}
    </>
  );
};

export default Form;
