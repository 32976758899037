import React from 'react';
import { ArrowRightCircleIcon } from '@heroicons/react/20/solid';

import LargeButton from '../../components/Buttons/LargeButton';
import HealthProfile from '../../components/HealthProfile';

interface IResultSceneProps {
  setSceneIndex: React.Dispatch<React.SetStateAction<number>>;
}

const ResultScene: React.FC<IResultSceneProps> = ({ setSceneIndex }) => {
  const handleNextScene = () => {
    setSceneIndex(1);
  };
  return (
    <div className='flex flex-col m-5 gap-10 justify-center sm:m-10 md:m-16 lg:gap-12'>
      <div className='flex flex-col gap-6 text-center md:justify-between md:flex-row md:gap-6 md:text-start'>
        <p className='text-2xl md:text-3xl lg:text-4xl font-bold'>
          Résultat de votre profil de forme :
        </p>
        <LargeButton
          text='Améliorer mes points faibles'
          RightIcon={ArrowRightCircleIcon}
          actionFunc={handleNextScene}
        />
      </div>
      <div className=''>
        <HealthProfile />
      </div>
    </div>
  );
};

export default ResultScene;
