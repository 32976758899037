import React, { useEffect, useState } from 'react';
import ResultScene from '../components/FUConfiguration/ResultScene';
import DimensionsChoicesScene from '../components/FUConfiguration/DimensionsChoicesScene';
import CategoriesChoices from '../components/FUConfiguration/CategoriesChoices';
import CategoriesConfiguration from '../components/FUConfiguration/CategoriesConfiguration';
import SelectMessageServices from '../components/FUConfiguration/SelectMessageServices';

interface SceneMap {
  [key: number]: JSX.Element;
}

const FollowUpConfiguration = () => {
  const [sceneIndex, setSceneIndex] = useState<number>(0);
  const [dimensionsSelection, setDimensionsSelection] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<
    (string | number)[]
  >([]);

  const SCENES: SceneMap = {
    0: <ResultScene setSceneIndex={setSceneIndex} />,
    1: (
      <DimensionsChoicesScene
        dimensionsSelection={dimensionsSelection}
        setDimensionsSelection={setDimensionsSelection}
        setSceneIndex={setSceneIndex}
      />
    ),
    2: (
      <CategoriesChoices
        selectedDimensions={dimensionsSelection}
        setSceneIndex={setSceneIndex}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
      />
    ),
    3: (
      <CategoriesConfiguration
        setSceneIndex={setSceneIndex}
        selectedCategories={selectedCategories}
      />
    ),
    4: <SelectMessageServices />,
  };

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      const message = 'Êtes-vous sûr de vouloir quitter la page ?';
      event.returnValue = message; // Standard pour la plupart des navigateurs
      return message; // Pour Internet Explorer
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return <div className=''>{SCENES[sceneIndex]}</div>;
};

export default FollowUpConfiguration;
