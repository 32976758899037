import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL_API;

console.log('BASE URL API: ', process.env.REACT_APP_BASE_URL_API);
console.log('BASE URL FRONT: ', process.env.REACT_APP_BASE_URL_FRONT);
console.log('NODE ENV: ', process.env.NODE_ENV);

export default axios.create({
  baseURL: BASE_URL,
});

function createAxiosInstanceWithToken(token) {
  return axios.create({
    baseURL: BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  });
}

// Créez une instance Axios personnalisée pour les requêtes JSON avec jeton
export function axiosPrivateWithToken(token) {
  return createAxiosInstanceWithToken(token);
}

// Créez une instance Axios personnalisée pour les requêtes de fichiers avec jeton
export function axiosPrivateFileWithToken(token) {
  const instance = createAxiosInstanceWithToken(token);
  instance.defaults.headers['Content-Type'] = 'multipart/form-data';
  return instance;
}
