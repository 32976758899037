import React, { useEffect, useState } from 'react';
import { FORM_STATUS } from '../../constants/Form';
import { Link, useNavigate } from 'react-router-dom';
import LargeButton from '../Buttons/LargeButton';
import Form from '../Form/Form';

const STATUS = {
  ...FORM_STATUS,
};

interface ICategoriesConfigurationProps {
  selectedCategories: (string | number)[];
  setSceneIndex: React.Dispatch<React.SetStateAction<number>>;
}

const LoadingScene: React.FC = () => {
  return (
    <div className='flex justify-center items-center h-screen w-screen flex-col'>
      <div className='flex justify-center flex-col w-3/5 gap-7 text-center items-center'>
        <div className='animate-pulse w-9/12 h-9 mr-3 bg-gray-200 rounded-full' />
        <div className='w-full'>
          <div className='animate-pulse h-4 bg-gray-200 rounded-full dark:bg-gray-700 mb-4'></div>
          <div className='animate-pulse h-3.5 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5'></div>
          <div className='animate-pulse h-3.5 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5'></div>
          <div className='animate-pulse h-3.5 bg-gray-200 rounded-full dark:bg-gray-700'></div>
        </div>
        <div className='flex flex-col items-center w-2/5'>
          <div className='animate-pulse w-48 h-10 mr-3 bg-gray-200 rounded-full' />
        </div>
      </div>
    </div>
  );
};

const EndScene: React.FC = () => {
  const navigate = useNavigate();

  const navigateToProfilePage = () => {
    navigate('/profil');
  };

  return (
    <div className='flex justify-center items-center h-screen w-screen flex-col'>
      <div className='flex justify-center flex-col w-3/5 gap-7 text-center items-center'>
        <h1 className='text-4xl font-bold my-6'>
          Merci d'avoir rempli le formulaire !
        </h1>
        <div>
          <p>
            Vous pouvez trouvez votre état de forme sur chacune des dimensions
            en allant consulter votre page profil.
          </p>
        </div>
        <div className='flex flex-col w-2/5'>
          <LargeButton text='Mon profil' actionFunc={navigateToProfilePage} />
        </div>
      </div>
    </div>
  );
};

const CategoriesConfiguration: React.FC<ICategoriesConfigurationProps> = ({
  selectedCategories,
  setSceneIndex,
}) => {
  const [configurationStatus, setConfigurationStatus] = useState<number>(
    STATUS['FETCHING_FORM']
  );
  const [formResponseStatus, setFormResponseStatus] = useState(0);
  const [formEndPoint, setFormEndPoint] = useState<string | null>(null);

  const answerEndPoint = '/api/follow_up/configuration/settings';

  useEffect(() => {
    if (selectedCategories.length > 0) {
      setFormEndPoint(
        '/api/follow_up/configuration/form?categoriesId=' +
          selectedCategories.join(';')
      );
    }
  }, [selectedCategories]);

  useEffect(() => {
    if (configurationStatus === STATUS['READY']) {
      setConfigurationStatus(STATUS['IN_PROGRESS']);
    } else if (configurationStatus === STATUS['FINISH']) {
      setSceneIndex(4);
    }
  }, [configurationStatus]);

  return (
    <div className='h-screen w-screen overflow-y-hidden'>
      {configurationStatus === STATUS['FETCHING_FORM'] && <LoadingScene />}
      <Form
        formEndpoint={formEndPoint}
        answerEndpoint={answerEndPoint}
        formStatus={configurationStatus}
        setFormStatus={setConfigurationStatus}
        setFormResponseStatus={setFormResponseStatus}
      />
    </div>
  );
};

export default CategoriesConfiguration;
