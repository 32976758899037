export const BG_COLOR_VARIANTS = {
  slate: {
    default: 'bg-slate-600 hover:bg-slate-500 active:bg-slate-700',
    dark: 'bg-slate-700 hover:bg-slate-600 active:bg-slate-800',
    light: 'bg-slate-500 hover:bg-slate-400 active:bg-slate-600',
  },
  gray: {
    default: 'bg-gray-600 hover:bg-gray-500 active:bg-gray-700',
    dark: 'bg-gray-700 hover:bg-gray-600 active:bg-gray-800',
    light: 'bg-gray-500 hover:bg-gray-400 active:bg-gray-600',
  },
  zinc: {
    default: 'bg-zinc-600 hover:bg-zinc-500 active:bg-zinc-700',
    dark: 'bg-zinc-700 hover:bg-zinc-600 active:bg-zinc-800',
    light: 'bg-zinc-500 hover:bg-zinc-400 active:bg-zinc-600',
  },
  neutral: {
    default: 'bg-neutral-600 hover:bg-neutral-500 active:bg-neutral-700',
    dark: 'bg-neutral-700 hover:bg-neutral-600 active:bg-neutral-800',
    light: 'bg-neutral-500 hover:bg-neutral-400 active:bg-neutral-600',
  },
  stone: {
    default: 'bg-stone-600 hover:bg-stone-500 active:bg-stone-700',
    dark: 'bg-stone-700 hover:bg-stone-600 active:bg-stone-800',
    light: 'bg-stone-500 hover:bg-stone-400 active:bg-stone-600',
  },
  red: {
    default: 'bg-red-600 hover:bg-red-500 active:bg-red-700',
    dark: 'bg-red-700 hover:bg-red-600 active:bg-red-800',
    light: 'bg-red-500 hover:bg-red-400 active:bg-red-600',
  },
  orange: {
    default: 'bg-orange-600 hover:bg-orange-500 active:bg-orange-700',
    dark: 'bg-orange-700 hover:bg-orange-600 active:bg-orange-800',
    light: 'bg-orange-500 hover:bg-orange-400 active:bg-orange-600',
  },
  amber: {
    default: 'bg-amber-600 hover:bg-amber-500 active:bg-amber-700',
    dark: 'bg-amber-700 hover:bg-amber-600 active:bg-amber-800',
    light: 'bg-amber-500 hover:bg-amber-400 active:bg-amber-600',
  },
  yellow: {
    default: 'bg-yellow-600 hover:bg-yellow-500 active:bg-yellow-700',
    dark: 'bg-yellow-700 hover:bg-yellow-600 active:bg-yellow-800',
    light: 'bg-yellow-500 hover:bg-yellow-400 active:bg-yellow-600',
  },
  lime: {
    default: 'bg-lime-600 hover:bg-lime-500 active:bg-lime-700',
    dark: 'bg-lime-700 hover:bg-lime-600 active:bg-lime-800',
    light: 'bg-lime-500 hover:bg-lime-400 active:bg-lime-600',
  },
  green: {
    default: 'bg-green-600 hover:bg-green-500 active:bg-green-700',
    dark: 'bg-green-700 hover:bg-green-600 active:bg-green-800',
    light: 'bg-green-500 hover:bg-green-400 active:bg-green-600',
  },
  emerald: {
    default: 'bg-emerald-600 hover:bg-emerald-500 active:bg-emerald-700',
    dark: 'bg-emerald-700 hover:bg-emerald-600 active:bg-emerald-800',
    light: 'bg-emerald-500 hover:bg-emerald-400 active:bg-emerald-600',
  },
  teal: {
    default: 'bg-teal-600 hover:bg-teal-500 active:bg-teal-700',
    dark: 'bg-teal-700 hover:bg-teal-600 active:bg-teal-800',
    light: 'bg-teal-500 hover:bg-teal-400 active:bg-teal-600',
  },
  cyan: {
    default: 'bg-cyan-600 hover:bg-cyan-500 active:bg-cyan-700',
    dark: 'bg-cyan-700 hover:bg-cyan-600 active:bg-cyan-800',
    light: 'bg-cyan-500 hover:bg-cyan-400 active:bg-cyan-600',
  },
  sky: {
    default: 'bg-sky-600 hover:bg-sky-500 active:bg-sky-700',
    dark: 'bg-sky-700 hover:bg-sky-600 active:bg-sky-800',
    light: 'bg-sky-500 hover:bg-sky-400 active:bg-sky-600',
  },
  blue: {
    default: 'bg-blue-600 hover:bg-blue-500 active:bg-blue-700',
    dark: 'bg-blue-700 hover:bg-blue-600 active:bg-blue-800',
    light: 'bg-blue-500 hover:bg-blue-400 active:bg-blue-600',
  },
  indigo: {
    default: 'bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700',
    dark: 'bg-indigo-700 hover:bg-indigo-600 active:bg-indigo-800',
    light: 'bg-indigo-500 hover:bg-indigo-400 active:bg-indigo-600',
  },
  violet: {
    default: 'bg-violet-600 hover:bg-violet-500 active:bg-violet-700',
    dark: 'bg-violet-700 hover:bg-violet-600 active:bg-violet-800',
    light: 'bg-violet-500 hover:bg-violet-400 active:bg-violet-600',
  },
  purple: {
    default: 'bg-purple-600 hover:bg-purple-500 active:bg-purple-700',
    dark: 'bg-purple-700 hover:bg-purple-600 active:bg-purple-800',
    light: 'bg-purple-500 hover:bg-purple-400 active:bg-purple-600',
  },
  fuchsia: {
    default: 'bg-fuchsia-600 hover:bg-fuchsia-500 active:bg-fuchsia-700',
    dark: 'bg-fuchsia-700 hover:bg-fuchsia-600 active:bg-fuchsia-800',
    light: 'bg-fuchsia-500 hover:bg-fuchsia-400 active:bg-fuchsia-600',
  },
  pink: {
    default: 'bg-pink-600 hover:bg-pink-500 active:bg-pink-700',
    dark: 'bg-pink-700 hover:bg-pink-600 active:bg-pink-800',
    light: 'bg-pink-500 hover:bg-pink-400 active:bg-pink-600',
  },
  rose: {
    default: 'bg-rose-600 hover:bg-rose-500 active:bg-rose-700',
    dark: 'bg-rose-700 hover:bg-rose-600 active:bg-rose-800',
    light: 'bg-rose-500 hover:bg-rose-400 active:bg-rose-600',
  },
} as const;
